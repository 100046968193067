 
import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-v",
  templateUrl: "./video.component.html",
})
export class VideoUploadComponent implements OnInit {
 
  constructor(private titleService:Title) {
    this.titleService.setTitle("Admin Video Page");
  }

  ngOnInit(): void {}

  showModal = true;
  toggleModal(){
    this.showModal = !this.showModal;
  }

}
