import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CallClient, CallAgent } from "@azure/communication-calling";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-contact",
  styleUrls: [ './contact.component.css' ],
  templateUrl: "./contact.component.html",
})
export class ContactComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  friendsfromservice: any[] = [];
  searchQuery: string = '';  
  filteredFriends: any[] = [];
  textShow: boolean = false;
  isLoading = false;
  
  private connectionString: string = "endpoint=https://communicationssckhaatumononprofit.unitedstates.communication.azure.com/;accesskey=8Sx+uFV965Li89UtjJhwzuiecJhGQRuZlL/Wwr2jLP47V4wzikYPIZNqkOTiZvv9o6XT6Y0Fp6blCmLpdTGJZw==";
  private callAgent: CallAgent | undefined;


  async ngOnInit(): Promise<void>  {
    await this.initializeCallAgent();
    
    //this.friendsfromservice = this.friends;
    this.http.get<Contact[]>(`${this.apiUrl}users` ).subscribe(result => {
      
      this.filteredFriends = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true );       
      this.friends = this.filteredFriends;
    });

  }

  private async initializeCallAgent(): Promise<void> {
    try {
      const identityClient = new CommunicationIdentityClient(this.connectionString);
      const { token } = await identityClient.createUserAndToken(["voip"]);
      this.callAgent = await new CallClient().createCallAgent(
        new AzureCommunicationTokenCredential(token)
      );
    } catch (error) {
      console.error('Error initializing call agent:', error);
    }
  }

  public async startCall(friend: any): Promise<void> {
    if (!this.callAgent) {
      console.error('Call agent is not initialized.');
      return;
    }
    this.isLoading = true;
    try {
      const call = this.callAgent.startCall(
        [{  phoneNumber: friend.phone }],
        { alternateCallerId: { phoneNumber: "+18333627691" } }
      );
    } catch (error) {
      console.error('Error starting call:', error);
    }
    this.isLoading = false;
  }
  callFriend(phone: string) {
    alert(`Calling ${phone}...`);
  }

  textFriend(phone: string) {
    alert(`Texting ${phone}...`);
  }



  constructor(private titleService:Title, public http: HttpClient, 
    private router: Router, private authService: MsalService) {
    this.titleService.setTitle("Contact Page");
    this.http.get<Contact[]>(`${this.apiUrl}users` ).subscribe(result => {
      
      this.friendsfromservice = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true );       
      
    });
    
  }
  userEmail: string = "";
  userName: string = "";
  userMessage: string = "";
  showMessageSent: boolean = false;
  showMessageSentFailed: boolean = false;
  SendMessage() {
    this.showMessageSentFailed = false;
    this.isLoading = true;

      
      let data = {
        "Email": this.userEmail,
        "Name": this.userName,
        "Message": this.authService.instance.getActiveAccount()?.username + "Send the following message: \n" + this.userMessage,
        
      };
      // Function to check if a value is not null, undefined, or empty
    const hasValue = (value: any) => value != null && value.trim() !== '';

    // Check if all fields have values
    const allFieldsValid = Object.values(data).every(hasValue);

    if (allFieldsValid) {
      // Proceed to the next step
      this.http.post(`${this.apiUrl}Subscribtion/inquire`, data).subscribe(result => {
        console.log(result);
        //Write thank you we will get back to very soon
        this.showMessageSent = true;
        this.isLoading = false;
      }, error => {
            console.error(error);
            this.isLoading = false;
          }
      );
      this.isLoading = false;
    } else {
      // Return false if any field is missing
      this.isLoading = false;
      this.showMessageSentFailed = true;
    }
      //`${this.apiUrl}openai/sendsms?text=`
      
       
    

  }

  private friends = [];

  


  search(): void {
    // Filter friends based on the search query
    this.filteredFriends = this.friends.filter(friend =>
      friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  //SendingSMS Modal
  isModalOpenWhatsApp = false;
  textareaValueWhatsApp:string = '';

  openModalWhatsApp(friend: any) {
    this.isModalOpenSms = true;
  }

  closeModalWhatsApp() {
    this.isModalOpenSms = false;
    this.textareaValueWhatsApp = ''; // Reset textarea value when closing modal
  }

  submitFormWhatsApp() {
    this.isLoading = true;
    // Handle form submission logic here
    console.log('Textarea value:', this.textareaValueWhatsApp);
    this.closeModalWhatsApp(); // Close modal after submission (you can modify this behavior as needed)
    this.isLoading = false;
  }
  //SendingSMS Modal
  isModalOpenSms = false;
  textareaValueSms: string = '';

  openModalSms(friend: any) {
    this.isModalOpenSms = true;
  }

  closeModalSms() {
    this.isModalOpenSms = false;
    this.textareaValueSms = ''; // Reset textarea value when closing modal
  }

  submitFormSms() {
    this.isLoading = true;
    // Handle form submission logic here
    console.log('Textarea value:', this.textareaValueSms);
    this.http.get<any[]>(`${this.apiUrl}openai/sendsms?text=` + this.textareaValueSms + '&fromPhone='+
      this.authService.instance.getActiveAccount()?.username +'&toPhone='+ +'7816668850').subscribe(result => {
      this.textareaValueSms = result.toString(); 
      this.isLoading = false;
    });
    this.closeModalSms(); // Close modal after submission (you can modify this behavior as needed)
    this.isLoading = false;
  }
}
interface Contact {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;
  whatisAppNumber: string;
}
