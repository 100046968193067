<div class="flex flex-wrap mt-4">

 
  <div class="w-full mb-12 px-4">
    <app-card-table></app-card-table>
  </div>

   <div class="w-full mb-12 px-4">
    <div *ngIf="!paidFor" class="hidden row col-md-12 align-items-center justify-content-center">  
  
      <h1>Buy this item ${{product.price}} </h1>
      
    </div> 
    <div *ngIf="paidFor" class=" hidden row col-md-12 align-items-center justify-content-center">  
      
      <h1>Yay ${{product.price}} </h1>
      
    </div> 

    
   </div>
</div>