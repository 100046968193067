import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, ChangeDetectorRef, Inject } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { FileUploadService } from "src/app/services/file-upload.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-card-donation",
  templateUrl: "./donation-card.component.html",
})
export class DonationCardComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  cloudDonations: Donations[];
  private _color = "light";
    constructor(private uploadService: FileUploadService, private http: HttpClient,
      private ref: ChangeDetectorRef, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
      private authService: MsalService,
      private msalBroadcastService: MsalBroadcastService) { 
        let useremail = this.authService.instance.getActiveAccount()?.username;
        useremail = 'basheabdi-buyer@gmail.com';
         //https://localhost:7201/donations/email?email=BMABDI%40HOTMAIL.COM
         //this.authService.instance.getActiveAccount()?.username
        http.get<Donations[]>(`${this.apiUrl}donations/email?email=` + useremail ).subscribe(result => { 
          this.cloudDonations= result;
        console.log(this.cloudDonations);
      }, error => console.error(error)); 
        
    
  }

  ngOnInit(): void {}
}
interface Donations {
  id: string;
  transactionId: string;
  email: string;
  phone: string;
  createdDate: any;
  amount: any; 
  comment: string; 
  
}; 
 