import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {YouTubePlayerModule} from '@angular/youtube-player';

//Azure OAuth2
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from './auth-config';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
      {
          httpMethod: 'GET',
          scopes: [...protectedResources.apiTodoList.scopes.read]
      },
      {
          httpMethod: 'POST',
          scopes: [...protectedResources.apiTodoList.scopes.write]
      },
      {
          httpMethod: 'PUT',
          scopes: [...protectedResources.apiTodoList.scopes.write]
      },
      {
          httpMethod: 'DELETE',
          scopes: [...protectedResources.apiTodoList.scopes.write]
      }
  ]);

  return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
  };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { MapsClientComponent } from "./views/current/maps/mapsclient.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { FileUploadComponent } from "./views/admin/file/files.component";
import { CommentComponent } from "./views/admin/comment/comment.component";
import { DonationComponent } from "./views/admin/donation/donation.component";
import { VideoUploadComponent } from "./views/admin/video/video.component";
import { RegistersComponent } from "./views/admin/register/register.component";
import { FriendsComponent } from "./views/admin/friends/friends.component";

import { TablesComponent } from "./views/admin/tables/tables.component";
import { ProgressDialog } from "./views/admin/loader/progress-dialog.component";
import { TableComponent } from "./views/auth/tables/table.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// curent views
import { VideoComponent } from "./views/current/video/video.component";
import { VideoPlayerComponent } from "./views/current/video/video.player.component";
import { VideosComponent } from "./views/current/videos/videos.component";
import { VideosPlayerComponent } from "./views/current/videos/videos.player.component";

//Doc components
import { DocumentComponent } from "./views/current/doc/doc.component";
import { TermsComponent } from "./views/current/terms/terms.component";
import { ContactComponent } from "./views/current/contact/contact.component";
import { AboutComponent } from "./views/current/about/about.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";


// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardFilesComponent } from "./components/cards/card-files/card-files.component";
import { CardDonationComponent } from "./components/cards/card-donation/card-donation.component";

import { CardFileComponent } from "./components/cards/card-file/card-file.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { DonationCardComponent } from "./components/cards/donation-table/donation-card.component";

 
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import { FileDropdownComponent } from "./components/dropdowns/file-dropdown/file-dropdown.component";


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxGlideModule } from 'ngx-glide';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

//Doc Viewer
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {EventService} from './services/event.service'
//import './instrument';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    FileDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardFilesComponent,
    CardDonationComponent,
    CardFileComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    DonationCardComponent,

    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    ProgressDialog,
    LoginComponent,
    RegisterComponent,
    FriendsComponent,
    IndexComponent,
    LandingComponent,
    VideoPlayerComponent,
    VideoComponent,
    VideosPlayerComponent,
    VideosComponent,
    DocumentComponent,
    TermsComponent,
    ContactComponent,
    AboutComponent,
    FileUploadComponent,
    CommentComponent,
    DonationComponent,
    VideoUploadComponent,
    RegistersComponent,
    TableComponent,
    MapsClientComponent
  ],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule, MsalModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    GoogleMapsModule,
    YouTubePlayerModule,
    NgxDocViewerModule,
    NgxGlideModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },   
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    EventService    
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
