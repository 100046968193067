<div>
  <app-auth-navbar></app-auth-navbar>
  <main>
    <section class="relative w-full h-full min-h-screen">
      
      <router-outlet></router-outlet>
      <app-footer-small [absolute]="true"></app-footer-small>
    </section>
  </main>
</div>
