<a
  class="text-blueGray-500 block py-1 px-3"
  href="#pablo"
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
  <i class="fas fa-ellipsis-v"></i>
</a>
<div class="min-w-48 z-50" #popoverDropdownRef>
  <div
    class="bg-white text-base float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
    [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  >
    <a
    (click)="approveDocument(videoId)"
      href="#/admin/files"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Approve
    </a>
    <a
    (click)="openComment(videoId)"
    href="#/admin/files"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Add Comment
    </a>
    <a
    (click)="deleteFile(videoId)"
    href="#/admin/files"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Delete
    </a>
    <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
    <a
      href="#"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Seprated link
    </a>
  </div>
</div>
<div *ngIf="selectedComment">


  <div class="flex p-2 space-x-4">
     
    <textarea [(ngModel)]="currentMsgToParent" id="description"class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write file description here..."></textarea>
  </div>
  <div class="flex p-2 space-x-4 w-full">
    <button class="px-4 py-2 text-white bg-red-500 rounded shadow-xl " [disabled]="!selectedComment" (click)="addComment()">Submit</button>
    <button class="px-4 py-2 text-white bg-red-500 rounded shadow-xl " [disabled]="!selectedComment" (click)="cancelComment()">Cancel</button>
  </div>
  </div>