<div class="flex flex-wrap">
  <div class="w-full px-4">
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
    <button (click)="startCall()">If you have question about the map, start call with the Admin. </button>
    <div #map style="height: 800px;"></div>
    
    </div>
    
  </div>
</div>



