<div class="flex flex-wrap">
  <div class="w-full">
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
    <div #map style="height: 800px;"></div>
    
    </div>
    
  </div>
</div>



