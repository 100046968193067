import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit, ViewChild,  ElementRef, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
 
import { NgxGlideComponent } from 'ngx-glide';
import { environment } from "src/environments/environment";

@Component({
 
  styleUrls: [ './videos.component.css' ],
  selector: "app-videos",
  templateUrl: "./videos.component.html",
  
})
export class VideosComponent implements OnInit {
  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent;
private apiUrl = environment.apiUrl;
  names: string[];

  public mobile: boolean = false;
  public videos: Video[] = [];
  public groupvideos: Video[][] = [];
  j = -1;
  @Input() href: string;
  localhttp: HttpClient;
  routeVideo: string;
  images: string[] = [];
  isLoading: boolean = true;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private el:ElementRef, 
    private ref: ChangeDetectorRef,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute) {   
  this.isLoading = true;
    this.localhttp = http;
    http.get<Video[]>(`${this.apiUrl}videos`).subscribe(result => {
      console.log('result', result.length);
      this.videos = result. filter(x => x.approved == true);
      console.log('result', this.videos.length);
      let userTestStatus: Video[] = [
        { "videoId": "iihJYAMIRP4", "thumbnail": "https://i.ytimg.com/vi/iihJYAMIRP4/hqdefault.jpg", "title":  "1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id:0 },
        { "videoId": "iDLWv3xM1s0", "thumbnail": "https://i.ytimg.com/vi/iDLWv3xM1s0/hqdefault.jpg", "title":  "2 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
        { "videoId": "bZQun8Y4L2A", "thumbnail": "https://i.ytimg.com/vi/6PRiAexITSs/hqdefault.jpg", "title":  "3 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
        { "videoId": "pJGDPEk45Jc", "thumbnail": "https://i.ytimg.com/vi/bZQun8Y4L2A/hqdefault.jpg", "title":  "4 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
        { "videoId": "hq6uEaMYf5U", "thumbnail": "https://i.ytimg.com/vi/hq6uEaMYf5U/hqdefault.jpg", "title":  "5 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
        { "videoId": "KMOV1Zy8YeM", "thumbnail": "https://i.ytimg.com/vi/KMOV1Zy8YeM/hqdefault.jpg", "title":  "6 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
        { "videoId": "sgIBC3yWa-M", "thumbnail": "https://i.ytimg.com/vi/sgIBC3yWa-M/hqdefault.jpg", "title":  "7 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
        { "videoId": "FyY0fEO5jVY", "thumbnail": "https://i.ytimg.com/vi/FyY0fEO5jVY/hqdefault.jpg", "title":  "8 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
        { "videoId": "8_0DJ9FOlOM", "thumbnail": "https://i.ytimg.com/vi/8_0DJ9FOlOM/hqdefault.jpg", "title":  "9 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
        { "videoId": "iihJYAMIRP4", "thumbnail": "https://i.ytimg.com/vi/iihJYAMIRP4/hqdefault.jpg", "title":  "10 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
        { "videoId": "6PRiAexITSs", "thumbnail": "https://i.ytimg.com/vi/6PRiAexITSs/hqdefault.jpg", "title":  "11 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 }
      ];

      //this.videos = userTestStatus;

      for (var i = 0; i < this.videos.length; i++) {
        if (i % 3 == 0) {
            this.j++;
            this.groupvideos[this.j] = [];
            this.groupvideos[this.j].push(this.videos[i]);
        }
        else {
            this.groupvideos[this.j].push(this.videos[i]);
        }
      }
      const data = JSON.parse(localStorage.getItem('myVideo'));
      if(data)
      {
       this.currentVideoId = data.videoid;
       localStorage.removeItem("myVideo");
      }
      else
      {
        this.currentVideoId = this.videos[0].videoId;
      }
      
      if(this.routeVideo != null){ 
      
        this.selectVideo(this.routeVideo);
      }
      this.isLoading = false;
    }, error => console.error(error));      

  }
  @ViewChild('draggable', { read: ElementRef, static:false }) draggable: ElementRef;
  ngOnInit(): void {    
    if (window.screen.width === 360) { // 768px portrait
      this.mobile = true;
    }
    const firstParam: string = this.route.snapshot.queryParamMap.get('Id');
    if(firstParam != null){ 
      this.routeVideo = firstParam;
    }
  }
  private apiLoaded2 = false
  ngAfterViewInit(): void {
      
    if (window.screen.width < 600) { // 768px portrait
      this.mobile = true;
    }
    this.showModal = true; 
    //invokecarousel();
  }

  showModal = true;
  currentVideoId: string;



  selectVideo(video: any) { 
    this.currentVideoId = video;
    this.draggable.nativeElement.classList.remove('hidden')
    console.log('removed hidden');
    let data = {
      'videoid': this.currentVideoId,
      'name': 'youtube'
    };
    localStorage.setItem('myVideo', JSON.stringify(data));
    //window.location.reload();
    this.ngOnInit();
  }
  approveVideo(video: any) {
    console.log('ivideod', video);
    this.videos.forEach( (item, index) => {
      if(item === video) this.videos.splice(index,1);
    });
    video.approved = false;
    this.localhttp.post(`${this.apiUrl}videos`, video).subscribe(result => {
     
      this.ref.detectChanges();
     // window.location.reload();
      this.ngOnInit();
      
    }, error => console.error(error));    
    
    this.currentVideoId = video;
    
    console.log('removed hidden')
  }

  saveVideo(videoid: any) {
    
    console.log('ivideod', videoid);
   
    let data = {
      "videoid": videoid,
      "documentid": "",
      "comment": "SAVE",
      "username":  this.authService.instance.getActiveAccount()?.username,
      "createdDate": new Date(),
      "approved": false
    };

    if(data.username == null){
      this.showMessageForDuration('You need to signin to save the video', 3000);
      return false;
    }
     
    this.localhttp.post(`${this.apiUrl}comment`, data).subscribe(result => {
      console.log(result);
    }, error => {
          console.error(error);
    });
    console.log('removed hidden')
  }
  
  isShown: boolean = false 
  userComment: any;
  clickComment(){
     
    this.isShown = true;
  }
  commentVideo(videoid: any) {
    this.isShown = false;
    console.log('ivideod', videoid);
    let data = {
      "videoid": videoid,
      "documentid": "",
      "comment": this.userComment,
      "username":  this.authService.instance.getActiveAccount()?.username,
      "createdDate": new Date(),
      "approved": false
    };
    if(data.username == null){
      this.showMessageForDuration('You need to signin to comment the video', 3000);
      return false;
    }
    
    this.localhttp.post(`${this.apiUrl}comment`, data).subscribe(result => {
      console.log(result);
    }, error => {
          console.error(error);
    });
    console.log('removed hidden')
  }
  showMessage: boolean = false;
  messageText: string = '';

  showMessageForDuration(message: string, duration: number) {
    this.messageText = message;
    this.showMessage = true;

    setTimeout(() => {
      this.hideMessage();
    }, duration);
  }
  hideMessage() {
    this.showMessage = false;
    this.messageText = '';
  }

   
   
  
}

interface Video {
  videoId: string;
  title: string;
  thumbnail: string;
  id: number;
  createdDate: any;
  approved: any;
}
