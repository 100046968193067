<app-auth-navbar></app-auth-navbar>
<main>
  <div
    class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
  >
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
        background-image: url('assets/img/flag.png');
      "
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
    <div class="container relative mx-auto">
      <div class="items-center flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
          <div class="pr-12">
            <h1 class="text-white font-semibold text-5xl p-2 ">
             
              {{ 'landing.heroMain' | translate }}

            </h1>
            <h3 class="text-white font-semibold text-3xl">
              {{ 'landing.heroTitle' | translate }}
            </h3>
           
            <p class="mt-4 text-lg text-blueGray-200">
              {{ 'landing.heroBody' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  </div>

  <section class="pb-20 bg-blueGray-200 -mt-24">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap">
        <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
          >
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
              >
                <i class="fas fa-award"></i>
              </div>
              <h6 class="text-xl font-semibold"> {{ 'landing.cat1Title' | translate }}</h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                {{ 'landing.cat1Body' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="w-full md:w-4/12 px-4 text-center">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
          >
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
              >
                <i class="fas fa-retweet"></i>
              </div>
              <h6 class="text-xl font-semibold">{{ 'landing.cat2Title' | translate }}</h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                {{ 'landing.cat2Body' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="pt-6 w-full md:w-4/12 px-4 text-center">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
          >
            <div class="px-4 py-5 flex-auto">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
              >
                <i class="fas fa-fingerprint"></i>
              </div>
              <h6 class="text-xl font-semibold">{{ 'landing.cat3Title' | translate }}</h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                {{ 'landing.cat3Body' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap items-center mt-32">
        <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
          <div
            class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
          >
            <i class="fa-solid fa-magnifying-glass"> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></i>
          </div>
          <h3 class="text-3xl mb-2 font-semibold leading-normal">
            {{ 'landing.cat4Title' | translate }}
          </h3>
          <p
            class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
          >
          {{ 'landing.cat4Body' | translate }}
          
          </p>
          <p
            class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
          >
          {{ 'landing.cat4ABody' | translate }}          
          </p>
          <br />
          <p
          class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
            <a [routerLink]="['/current/doc']" class="font-bold text-blueGray-700 mt-8 mb-6">
              Check current documents!
            </a>
          </p>
          
          <br />
          
        </div>

        <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-600"
          >
            <img
              alt="..."
              src="assets/img/transparency.jpg"
              class="w-28 h-28 rounded-full border-4 border-white shadow-md"
              style="max-height: 300px;"
            />
            <blockquote class="relative p-8 mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                class="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                  points="-30,95 583,95 583,65"
                  class="text-red-600 fill-current"
                ></polygon>
              </svg>
              <h4 class="text-xl font-bold text-white ">{{ 'landing.cat5Title' | translate }}</h4>
              <p class="text-md font-light mt-2 text-white">
                {{ 'landing.cat5Body' | translate }}
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="relative py-20">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-white fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div class="container mx-auto px-4">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img
            alt="..."
            class="max-w-full rounded-lg shadow-lg"
            src="assets/img/connections.jpg"
          />
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <div
              class="text-red-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-red-300"
            >
              <i class="fas fa-rocket text-xl"></i>
            </div>
            <h3 class="text-3xl font-semibold">{{ 'landing.cat6Title' | translate }}</h3>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              {{ 'landing.cat6Body' | translate }}
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                    >
                      <i class="fas fa-fingerprint"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      {{ 'landing.cat6Point1' | translate }}
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                    >
                      <i class="fab fa-html5"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      {{ 'landing.cat6Point2' | translate }}
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                    >
                      <i class="far fa-paper-plane"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      {{ 'landing.cat6Point3' | translate }}
                    </h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-20 pb-48">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-center text-center mb-24">
        <div class="w-full lg:w-6/12 px-4">
          <h2 class="text-4xl font-semibold">{{ 'landing.cat7Title' | translate }}</h2>
          <p class="text-lg leading-relaxed m-4 text-blueGray-500">
            {{ 'landing.cat7Body' | translate }}
          </p>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div class="px-6">
            <img
              alt="..."
              src="assets/img/icons8-user-avatar-50.png"
              class="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div class="pt-6 text-center">
              <h5 class="text-xl font-bold">Ryan Ismael</h5>
              <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                Orphans Coordinator 
              </p>
              <div class="mt-6">
                <button
                  class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >                  
                <a
                class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
                href="https://twitter.com/sscaidnow"
                target="_blank"
              ><i class="fab fa-twitter"></i>
            </a>
                </button>
                
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div class="px-6">
            <img
              alt="..."
              src="assets/img/icons8-user-avatar-50.png"
              class="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div class="pt-6 text-center">
              <h5 class="text-xl font-bold">Fatima Hadid</h5>
              <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                Disabled Coordinator 
              </p>
              <div class="mt-6">
                 
                <button
                  class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >                 
                  <a _ngcontent-qno-c34="" 
                  href="https://www.facebook.com/profile.php?id=100081282623215" target="_blank" class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"><i class="fab fa-facebook-f"></i></a>
                </button>
                
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div class="px-6">
            <img
              alt="..."
              src="assets/img/icons8-user-avatar-50.png"
              class="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div class="pt-6 text-center">
              <h5 class="text-xl font-bold">Mohamed Cabdilahi</h5>
              <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                Healthcare Coordinator
              </p>
              <div class="mt-6">
                
                <button
                  class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >                  
                  <a _ngcontent-qno-c34="" href="https://www.youtube.com/channel/UCT1E9ysIHrXgFwySwPGk8Kg" target="_blank" class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"><i class="fab fa-dribbble"></i></a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
          <div class="px-6">
            <img
              alt="..."
              src="assets/img/icons8-user-avatar-50.png"
              class="shadow-lg rounded-full mx-auto max-w-120-px"
            />
            <div class="pt-6 text-center">
              <h5 class="text-xl font-bold">Bashe Abdirahman</h5>
              <p class="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                Founder and CEO
              </p>
              <div class="mt-6">
                <button
                  class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >                  
                 
                <a
            class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://twitter.com/sscaidnow"
            target="_blank"
          ><i class="fab fa-twitter"></i></a>
                </button>
                <button
                  class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >                 
                  <a _ngcontent-qno-c34="" 
                  href="https://www.facebook.com/profile.php?id=100081282623215" target="_blank" class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"><i class="fab fa-facebook-f"></i></a>
                </button>
                <button
                  class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                >                  
                  <a _ngcontent-qno-c34="" href="https://www.youtube.com/channel/UCT1E9ysIHrXgFwySwPGk8Kg" target="_blank" class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"><i class="fab fa-dribbble"></i></a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pb-20 relative block bg-blueGray-800">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-800 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
      <div class="flex flex-wrap text-center justify-center">
        <div class="w-full lg:w-6/12 px-4">
          <h2 class="text-4xl font-semibold text-white">{{ 'landing.cat8Title' | translate }}</h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
            {{ 'landing.cat8Body' | translate }}
          </p>
        </div>
      </div>
      <div class="flex flex-wrap mt-12 justify-center">
        <div class="w-full lg:w-3/12 px-4 text-center">
          <div
            class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
          >
            <i class="fas fa-medal text-xl"></i>
          </div>
          <h6 class="text-xl mt-5 font-semibold text-white">
            {{ 'landing.cat9Title' | translate }}
          </h6>
          <p class="mt-2 mb-4 text-blueGray-400">
            {{ 'landing.cat9Body' | translate }}
          </p>
        </div>
        <div class="w-full lg:w-3/12 px-4 text-center">
          <div
            class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
          >
            <i class="fas fa-poll text-xl"></i>
          </div>
          <h5 class="text-xl mt-5 font-semibold text-white">
            {{ 'landing.cat10Title' | translate }}
          </h5>
          <p class="mt-2 mb-4 text-blueGray-400">
            {{ 'landing.cat10Body' | translate }}
          </p>
        </div>
        <div class="w-full lg:w-3/12 px-4 text-center">
          <div
            class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
          >
            <i class="fas fa-lightbulb text-xl"></i>
          </div>
          <h5 class="text-xl mt-5 font-semibold text-white">{{ 'landing.cat11Title' | translate }}</h5>
          <p class="mt-2 mb-4 text-blueGray-400">
            {{ 'landing.cat11Body' | translate }}
          </p>
        </div>
        <div class="w-full lg:w-3/12 px-4 text-center">
          <div
            class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
          >
            <i class="fas fa-lightbulb text-xl"></i>
          </div>
          <h5 class="text-xl mt-5 font-semibold text-white">{{ 'landing.cat12Title' | translate }}</h5>
          <p class="mt-2 mb-4 text-blueGray-400">
            {{ 'landing.cat12Body' | translate }}
          </p>
        </div>

      </div>
    </div>
  </section>
 

  <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div class="w-full lg:w-10/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
          >
            <div class="flex-auto p-5 lg:p-10">
               
              <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                {{ 'landing.cat13Title' | translate }}
              </p>
              <form class="w-full p-12">
                <div class="flex items-center border-b border-teal-500 py-2">
                  <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Email"  aria-label="Email" name="userEmail" #userEmail> 
                  <a  class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" (click)="subscribe(userEmail.value)">Sign Up</a>
                  <div *ngIf="isLoading" class="spinner"></div>
                </div>
                
                  
                   
                 
              </form>
              <div *ngIf="isShown" class="row container-fluid text-blue-600"  style="color:blue" id="divshow" >
                Thank you for subscriribing to our newsletter!
                
                </div>
                <div *ngIf="isOptin" class="row container-fluid text-blue-600"  style="color:blue" id="divshow" >
                  please provide your email and subscribe in order to optIn!
                  
                  </div>
                <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                  {{ 'landing.optIn' | translate }}
                </p>
                <form  id="optinForm">
                <div class="w-full lg:w-12/12">
                  <div class="bg-white p-8 rounded shadow-md lg:w-12/12">
                   
            
                    <!-- Email Opt-In -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-600">Email Opt-In</label>
                        <div class="mt-1" >
                            <input type="checkbox" id="emailOptIn" name="emailOptIn" [(ngModel)]="emailOptIn" class="form-checkbox h-5 w-5 text-indigo-600">
                            <label for="emailOptIn" class="ml-2 text-gray-700">Subscribe to Email</label>
                        </div>
                    </div>
            
                    <!-- SMS Opt-In -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-600">SMS Opt-In</label>
                        <div class="mt-1">
                            <input type="checkbox" id="smsOptIn" name="smsOptIn" [(ngModel)]="smsOptIn" class="form-checkbox h-5 w-5 text-indigo-600">
                            <label for="smsOptIn" class="ml-2 text-gray-700">Subscribe to SMS</label>
                        </div>
                    </div>
            
                    <!-- Voice Call Opt-In -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-600">Voice Call Opt-In</label>
                        <div class="mt-1">
                            <input type="checkbox" id="voiceCallOptIn" name="voiceCallOptIn" [(ngModel)]="voiceCallOptIn" class="form-checkbox h-5 w-5 text-indigo-600">
                            <label for="voiceCallOptIn" class="ml-2 text-gray-700">Subscribe to Voice Call</label>
                        </div>
                        
                    </div>
            
                    <!-- Submit Button -->
                    <div class="mt-6">
                      <button class="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600" type="button" (click)="optIn()">Submit</button>
                      <div *ngIf="isLoading" class="spinner"></div>
                    </div>
                    
                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
</main>
<app-footer></app-footer>
