<!--Request access to file upload-->


<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
     [ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'">
  <div *ngIf="isNotAllowed" class="max-w-md mx-auto bg-white p-8 rounded-md shadow-md">
    <h2 class="text-2xl font-semibold mb-4">Request Access to Upload File</h2>
  
    
    <div class="flex p-2 space-x-4">
      <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
      <textarea [(ngModel)]="currentMsgToParent" id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write reason to upload file here..."></textarea>
    </div>
    <div class="flex p-2 space-x-4">
      <button class="px-4 py-2 text-white bg-red-500 rounded shadow-xl w-full"  (click)="request()">Submit</button>
    </div>
  
        <div class="flex items-center">
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
                Request Access
            </button>
        </div>
  
  </div>
  <div *ngIf="isAllowed" class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3 class="font-semibold text-lg"
            [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'">
          Upload Finantial documents
        </h3>
      </div>
    </div>
  </div>
  <div *ngIf="isAllowed" class="flex content-center items-center justify-center h-full">
    <div class="w-full px-4">
      <div class="flex justify-center mt-8">
        <div class="rounded-lg shadow-xl bg-gray-50 lg:w-1/2">
          <div class="m-4">
            
            <div class="flex items-center justify-center w-full">
              <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                <div class="flex flex-col items-center justify-center pt-7">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                       fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                          clip-rule="evenodd" />
                  </svg>
                  <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    Select document
                  </p>
                </div>
                <input type="file" class="opacity-0" (change)="selectFile($event)" />
              </label>
            </div>
          </div>
          <div class="flex p-2 space-x-4">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
            <textarea [(ngModel)]="currentMsgToParent" id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write file description here..."></textarea>
          </div>
          <div class="flex p-2 space-x-4">
            <button class="px-4 py-2 text-white bg-red-500 rounded shadow-xl w-full" [disabled]="!selectedFiles" (click)="upload()">Submit</button>
          </div>
          <div *ngIf="currentFile" class="progress my-3">
            <div class="progress-bar progress-bar-info progress-bar-striped"
                 role="progressbar"
                 attr.aria-valuenow="{{ progress }}"
                 aria-valuemin="0"
                 aria-valuemax="100"
                 [ngStyle]="{ width: progress + '%' }">
              {{ progress }}%
            </div>
          </div>

          <div *ngIf="message" class="alert alert-secondary" role="alert">{{ message }}</div>
        </div>
      </div>

      <div class="flex flex-wrap mt-6 relative">
        <div class="w-1/2">
          <a href="javascript:void(0)" class="text-blueGray-200">
            <small>Forgot password?</small>
          </a>
        </div>
        <div class="w-1/2 text-right">
          <a [routerLink]="['/admin/settings']" class="text-blueGray-200">
            <small>Create new account</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAllowed"
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  [ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3
          class="font-semibold text-lg"
          [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
        >
          Uploaded Documents
        </h3>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            ">
            Project
          </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            ">
            Comment
          </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            ">
            Date
          </th>
          
          
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "></th>
        </tr>
      </thead>
      <tbody>
        <tr  *ngFor="let currentFle of cloudfile" >
          <th
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
          >
            <img
              src="assets/img/bootstrap.jpg"
              class="h-12 w-12 bg-white rounded-full border"
              alt="..."
            />
            <span
              class="ml-3 font-bold"
              [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
            >
            {{currentFle.fileName}}
            </span>
          </th>
          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4"
        >
        {{currentFle.fileData}}
        
        </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{currentFle.createdDate}}
          
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
          >
            <app-table-dropdown [videoId]="currentFle.fileName"></app-table-dropdown>
          </td>
        </tr>       
      </tbody>
    </table>
  </div>
</div>

