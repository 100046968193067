 
<div class="container mt-24 mx-auto px-4 h-full">
  
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full  px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0">
          
    <!-- About Us Section -->
    <section id="about" class="bg-gray-200 py-12">
        <div class="container mx-auto">
            <h2 class="text-3xl font-bold mb-4">About Us</h2>
            <p class="text-gray-700 leading-loose">
                SSC Khaatumo Nonprofit Organization is dedicated to providing support and care for orphans, disabled individuals,
                and those in need of healthcare assistance. Our mission is to make a positive impact on the lives of vulnerable
                members of our community.
            </p>
        </div>
    </section>

    <!-- Mission Section -->
    <section id="mission" class="bg-gray-200 py-12">
        <div class="container mx-auto">
            <h2 class="text-3xl font-bold mb-4">Our Mission</h2>
            <p class="text-gray-700 leading-loose">
                At SSC Khaatumo, our mission is to create a nurturing environment for orphans, empower disabled individuals,
                and contribute to the well-being of our community through accessible healthcare initiatives. We believe in
                fostering a sense of belonging and providing opportunities for a brighter future.
            </p>
        </div>
    </section>

    <!-- Programs Section -->
    <section id="programs" class="bg-gray-200 py-12">
        <div class="container mx-auto">
            <h2 class="text-3xl font-bold mb-4">Our Programs</h2>
            <p class="text-gray-700 leading-loose">
                We run various programs to address the unique needs of orphans, disabled individuals, and those requiring
                healthcare assistance. Our programs include educational support, vocational training, healthcare clinics, and
                community outreach initiatives. Through these efforts, we aim to make a lasting impact on the lives we touch.
            </p>
        </div>
    </section>

    <!-- Contact Section -->
    <section id="contact" class="bg-gray-200 py-12">
        <div class="container mx-auto">
            <h2 class="text-3xl font-bold mb-4">Contact Us</h2>
            <p class="text-gray-700 leading-loose">
                If you have any questions or would like to get involved in our mission, please feel free to contact us.
            </p>
            <p class="text-gray-700 leading-loose">
                Email: <a href="mailto:sscaidnow@068752af-a79d-4ad1-86bb-034300a1e347.azurecomm.net" class="text-blue-500">sscaidnow@068752af-a79d-4ad1-86bb-034300a1e347.azurecomm.net</a>
            </p>
        </div>
    </section>
        </div>
      </div>
    </div>
</div>
 