
<div class="container mt-24 mx-auto px-4 h-full">
  
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full  px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0">
            
            <div class="container  mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">

                <h1 class="text-3xl font-bold mb-6">Terms and Conditions</h1>
        
                
                <h2 class="text-2xl font-bold mt-6">Communication Opt-In</h2>
        <p class="mb-4">By using SSC Aid Now's services you have the options to opt-in:</p>

        <ul class="list-disc ml-8">
            <li class="mb-2">Email: You may receive informational emails regarding our activities and events.</li>
            <li class="mb-2">SMS: You may receive text messages with updates and important announcements.</li>
            <li class="mb-2">Voice Call: You may receive voice calls for urgent notifications and outreach.</li>
        </ul>

        <p class="mt-4">You can opt-out of these communications at any time by following the instructions provided in the messages or by contacting SSC Aid Now directly.</p>

        
                <p class="mt-4">These terms and conditions are subject to change without notice. It is your responsibility to check for updates regularly.</p>
        
                <h2 class="text-2xl font-bold mt-6">User Conduct</h2>
                <p class="mb-4">While using SSC Aid Now's services, you agree not to:</p>
        
                <ul class="list-disc ml-8">
                    <li class="mb-2">Violate any applicable laws or regulations.</li>
                    <li class="mb-2">Interfere with the proper functioning of the services.</li>
                    <li class="mb-2">Engage in any form of unlawful, harassing, or discriminatory behavior.</li>
                    <!-- Add more conduct guidelines as needed -->
                </ul>
        
                <p class="mt-4">Failure to comply with these terms may result in the termination of your access to SSC Aid Now's services.</p>
        
            </div>

        </div>
      </div>
    </div>
</div>