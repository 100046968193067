<nav
  class="bg-black lg:bg-none md:bg-none top-0 absolute z-50 w-full flex flex-wrap md:flex-nowrap items-center justify-between px-2 py-3 navbar-expand-lg w-full"
>

 
   <div
    class="container justify-start  px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
       
      <button class="bg-transparent font-bold lg:py-2 rounded inline-flex items-center visible md:invisible md:hidden text-center text-white"  (click)="toggleModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="26" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
          <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
          <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
        </svg>
        <a>Donate</a>
      </button>
      <button class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              (click)="setNavbarOpen()">
        <i class="text-white fas fa-bars"></i>
      </button>
    </div>
   
    <div
      class="lg:flex flex justify-end items-center  lg:bg-opacity-0 lg:shadow-none bg-transparent "
      [ngClass]="{ hidden: !navbarOpen, 'block rounded shadow-lg': navbarOpen }"
      id="example-navbar-warning" 
    >
      <ul class="flex flex-col lg:flex-row list-none mr-auto">
        <li class="flex items-center">
          <a class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
          [routerLink]="['#']" >
            <i class="lg:text-blueGray-200 text-blueGray-400  text-lg leading-lg mr-2"></i>
            {{ 'nav.home' | translate }}
          </a>
          </li>
       
        <li class="flex items-center">
          <a class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
          (click)="toggleModal()" >
            <i class="lg:text-blueGray-200 text-blueGray-400  text-lg leading-lg mr-2"></i>
            {{ 'nav.donate' | translate }}
          </a>
        </li>
        <li class="flex items-center">
          <app-pages-dropdown class="block"></app-pages-dropdown>
        </li>
        <li class="flex items-center">
          <a class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
             [routerLink]="['/current/videos']" >
            <i class="lg:text-blueGray-200 text-blueGray-400 text-lg leading-lg mr-2"></i>
            {{ 'nav.video' | translate }}
          </a>
        </li>
        <li class="flex items-center">
          <a class="text-white lg:hover:text-blueGray-200  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
             [routerLink]="['/current/doc']" >
            <i class="lg:text-blueGray-200 text-blueGray-400 text-lg leading-lg mr-2"></i>
            {{ 'nav.document' | translate }}
          </a>
        </li>
        <li class="flex items-center">
          <a class="text-white lg:hover:text-blueGray-200  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
             [routerLink]="['/current/maps']" >
            <i class="lg:text-blueGray-200 text-blueGray-400 text-lg leading-lg mr-2"></i>
            Maps
          </a>
        </li>
       
        <li class="flex items-center">
          <a
            class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.facebook.com/profile.php?id=100081282623215"
            target="_blank"
          >
            <i
              class=" text-blueGray-400 fab fa-facebook text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">Share</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="text-white lg:hover:text-blueGray-200  px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://twitter.com/sscaidnow"
            target="_blank"
          >
            <i
              class="text-white text-blueGray-400 fab fa-twitter text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">Tweet</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.youtube.com/channel/UCT1E9ysIHrXgFwySwPGk8Kg"
            target="_blank"
          >
            <i
              class="text-white fab fa-youtube text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">Youtube</span>
          </a>
        </li>

        <li class="flex items-center">
          <div class="background-transparent bg-no-repeat bg-full px-3 py-4 lg:py-2 ml:-2.5">
            
            <div class="flex flex-row items-center">
              
              <div
                class="text-blueGray-500 block"
                           
              >
                <div class="items-center flex">
                  <span
                    class="w-12 h-12 text-lg text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
                  >
                    <div  class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                      
                      <span class="font-medium text-gray-600 dark:text-gray-300">
                        <div *ngIf="so" class="flex flex-row items-center">
                          <select #langSelect (change)="saveLang(langSelect.value)" class="background-transparent bg-no-repeat bg-full " style="border: none; outline: none; scroll-behavior: smooth; background: transparent; background-image:url('https://flagcdn.com/40x30/so.png')">
                            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang" style="Border: none; color: black;">
            
                              <div class="flex flex-row items-center font-bold text-lg">
                                {{ lang }}        
                              </div>
                            </option>
                          </select>
                        </div>
                        <div *ngIf="en" class="flex flex-row items-center">
                          <select #langSelect (change)="saveLang(langSelect.value)" class="background-transparent bg-no-repeat bg-full " style="border: none; outline: none; scroll-behavior: smooth; background: transparent; background-image:url('https://flagcdn.com/40x30/us.png')">
                            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang" style="Border: none; color: black;">
            
                              <div class="flex flex-row items-center font-bold text-lg">
                                {{ lang }}        
                              </div>
                            </option>
                          </select>
                        </div>
                        
                    </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
       

        <li class="flex items-center">          
          <app-user-dropdown class="block"></app-user-dropdown>
        </li>
       
      </ul>
    </div>
  </div>

</nav>
<div class='fixed bottom-0 w-full'>
  <button class="bg-transparent font-bold lg:py-2 rounded inline-flex items-center my-8 float-right px-5 py-2 bg-red-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none" style="color:white" (click)="toggleModal()">
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="26" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
      <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
      <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
    </svg>
    
  </button>
</div>

<div #draggable class="flex h-screen overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none justify-center items-center flex" style="background-image: url('assets/img/flag.png');">

  <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
    <div class="mt-3 text-center">
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
        <svg class="h-6 w-6 text-purple-600" fill="none" stroke="currentColor"
             viewBox="0 0 24 24"
             xmlnx="http://www.w.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7">
          </path>
        </svg>
      </div>
      <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
         
        <div class="p-5">
            <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Make a difference today</h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Support our cause and make a positive impact by clicking the donate button now!</p>
            <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                 <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
            </a>
        </div>
    </div>
      <div class="mt-2 px-7 py-3">

        <div class="flex content-center items-center justify-center h-full" id="paypal-donate-button-container"></div>

      </div>
     
    </div>
  </div>
</div>
