import { createPopper } from "@popperjs/core";
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren, AfterViewInit } from "@angular/core";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { TranslateService } from "@ngx-translate/core";
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-user-dropdown",
  templateUrl: "./user-dropdown.component.html",
})
export class UserDropdownComponent implements AfterViewInit {
  private apiUrl = environment.apiUrl;
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  navbarOpen = false;
  en = false;
  title = 'Microsoft identity platform';
  isIframe = false;
  loginDisplay = false;
  user!: { firstName: string; lastName: string; };
  private readonly _destroying$ = new Subject<void>();
  welcome!: string;
  usernameLabel!: string;
  passwordLabel!: string;
  http: HttpClient
  langSelect: string;
  constructor(private el: ElementRef, public translate: TranslateService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService,
  private httpClient: HttpClient) {
    translate.addLangs(['en', 'so']);
    this.langSelect = localStorage.getItem('langSelect') || 'en';
    translate.setDefaultLang(this.langSelect);
    translate.currentLang = this.langSelect;
    //translate.setDefaultLang('en');
    
    const browserLang = translate.getBrowserLang();
    translate.use(this.langSelect);

    this.http = httpClient;

  }
  ngOnInit(): void {        

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); 
    
    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
            if (this.authService.instance.getAllAccounts().length === 0) {
                window.location.pathname = "/";
            } else {
                this.setLoginDisplay();
            }
        });

    this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this._destroying$)
        )
        .subscribe(() => {
            this.setLoginDisplay();
            this.checkAndSetActiveAccount();
        });
   
  }
  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        // add your code for handling multiple accounts here
        this.authService.instance.setActiveAccount(accounts[0]);        
    }
    
    this.http.get(`${this.apiUrl}users/email?email=` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {

    }, error => console.error(error)); 
    
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  login() {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                      
                  });
          } else {
              this.authService.loginPopup()
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);

                  });
          }
      } else {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);

          } else {
              this.authService.loginRedirect();

          }
      }
  }
    logout() {
      const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          this.authService.logoutPopup({
              account: activeAccount,
          });
      } else {
          this.authService.logoutRedirect({
              account: activeAccount,
          });
      }
  }
}
