<a
  class="text-blueGray-500 block"
  href="#pablo"
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
  <div class="items-center flex">
    <span
      class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
    >
      
      <div  *ngIf="loginDisplay"  class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">
          <img
          alt="..."
          src="assets/img/profile.png"
          class="w-28 h-28 rounded-full border-4 border-white shadow-md"
          style="max-height: 300px;"
        />
        </span>
    </div>
    <div  *ngIf="!loginDisplay"  class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
      <span class="font-medium text-gray-600 dark:text-gray-300">
        <img
          alt="..."
          src="assets/img/noprofile.png"
          class="w-28 h-28 rounded-full border-4 border-white shadow-md"
          style="max-height: 300px;"
        />
      </span>
  </div>
    </span>
  </div>
</a>
<div class="min-w-48 z-50" #popoverDropdownRef>
  <div
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
    [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  >
    
     
    <a 
      [routerLink]="['/admin/settings']"
    
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Register
    </a>
    <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
    <a *ngIf="loginDisplay" 
        (click)="logout()"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Signout
    </a>
    <a *ngIf="!loginDisplay" 
        (click)="login()"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Sign-in
    </a>
  </div>
</div>
