<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">My account</h6>
      
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form id="optinForm" >
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        User Information
      </h6>
      <div class="flex flex-wrap">
        
      

        <div class="w-full lg:w-full px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Full Name
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="currentName"
            [(ngModel)]="currentName"
              
            />
          </div>
        </div>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Email address
            </label>
            <input
              type="email"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              
              name="Email"
              [(ngModel)]="currentUser"
              autocomplete="off"
                readonly
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              WhatsApp Number
            </label>
            <div class="flex items-center">
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="WhatsApp Number"
            [(ngModel)]="currentWhatsAppNumber">
            <button (click)="registerPhone()" class="bg-blue-500 text-blueGray-600 p-2 rounded-full transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300">
              <i class="fas fa-icon">Change</i>
          </button>
        </div>
             
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Phone Number
            </label>
            <div class="flex items-center">
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="PhoneNumber"
            [(ngModel)]="currentPhone">
            <button (click)="registerPhone()" class="bg-blue-500 text-blueGray-600 p-2 rounded-full transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300">
              <i class="fas fa-icon">Change</i>
          </button>
        </div>
             
          </div>
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-blueGray-300" />

      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Contact Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
               City / State
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              value="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
              name="Location"                
              placeholder="location" 
              [(ngModel)]="currentLocation"
            />
          </div>
        </div>
        
        <hr class="mt-6 border-b-1 border-blueGray-300" />

      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Communication Opt-In
      </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="bg-white p-8 rounded shadow-md lg:w-12/12">
           
    
            <!-- Email Opt-In -->
            <div class="mb-4">
                <label class="block text-sm font-medium text-gray-600">Email Opt-In</label>
                <div class="mt-1">
                    <input type="checkbox" id="emailOptIn" name="emailOptIn"  [(ngModel)]="emailOptIn" class="form-checkbox h-5 w-5 text-indigo-600">
                    <label for="emailOptIn" class="ml-2 text-gray-700">Subscribe to Email</label>
                </div>
            </div>
    
            <!-- SMS Opt-In -->
            <div class="mb-4">
                <label class="block text-sm font-medium text-gray-600">SMS Opt-In</label>
                <div class="mt-1">
                    <input type="checkbox" id="smsOptIn" name="smsOptIn"  [(ngModel)]="smsOptIn" class="form-checkbox h-5 w-5 text-indigo-600">
                    <label for="smsOptIn" class="ml-2 text-gray-700">Subscribe to SMS</label>
                </div>
            </div>
    
            <!-- Voice Call Opt-In -->
            <div class="mb-4">
                <label class="block text-sm font-medium text-gray-600">Voice Call Opt-In</label>
                <div class="mt-1">
                    <input type="checkbox" id="voiceCallOptIn" name="voiceCallOptIn"  [(ngModel)]="voiceCallOptIn" class="form-checkbox h-5 w-5 text-indigo-600">
                    <label for="voiceCallOptIn" class="ml-2 text-gray-700">Subscribe to Voice Call</label>
                </div>
            </div>
    
          </div>
         
        </div>
        
      </div>

      <hr class="mt-6 border-b-1 border-blueGray-300" />

      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        About Me
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              About me
            </label>
            <textarea
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="4"
              name="currentDescription"                
              placeholder="Description" 
              [(ngModel)]="currentDescription"
            >
            
                </textarea
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="flex justify-end">
            <button class="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600" type="button" (click)="InsertUpdateUser()">Submit</button>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>
