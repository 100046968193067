import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
declare function makeDonation(argm1:string): void;
import { environment } from "src/environments/environment";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
  private apiUrl = environment.apiUrl;
  constructor() {}

  @ViewChild('draggable', { read: ElementRef, static:false }) draggable: ElementRef;
  ngOnInit(): void {
      makeDonation( `${this.apiUrl}donations`);
    
  }
  ngAfterViewInit(): void {
    this.draggable.nativeElement.classList.add('hidden')
  }
  showModal = true;
  toggleModal(){  
    this.showModal = !this.showModal;   
    this.toggleCollapseShow('hidden')
    //setTimeout(() => {
      if(!this.showModal) {
        this.draggable.nativeElement.classList.remove('hidden')
        console.log('removed hidden')
      }
      else {
        this.draggable.nativeElement.classList.add('hidden')
        console.log('added hidden')
      }
    //}, 100);
    
  }
  hideModal(){
    this.draggable.nativeElement.classList.add('hidden')
    console.log('added hidden')
    this.toggleCollapseShow('hidden')
  }
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }

}
