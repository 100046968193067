import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit, ViewChild,  ElementRef, ChangeDetectorRef } from "@angular/core";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {
  private apiUrl = environment.apiUrl;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private el:ElementRef, 
    private ref: ChangeDetectorRef, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router, private titleService:Title) { 
      this.localhttp = http;
      http.get<User>(`${this.apiUrl}users/email?email=` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {
      this.Users = result;
      this.titleService.setTitle("Registration Page");       
      this.currentDescription = this.Users.description;
      this.currentPhone = this.Users.phone;
      this.currentWhatsAppNumber = this.Users.whatisAppNumber;
      this.currentLocation = this.Users.location;
      this.verificationCode = 0;
 
    }, error => console.error(error));      
  }
  currentUser: string;
  currentPhone: string;
  currentWhatsAppNumber: string;
  currentLocation: string;
  currentDescription: string;
  localhttp: HttpClient;
  public Users: User;
  activeAccount: any;
  showModalDisabled: boolean = false;
  showVerificationInput: boolean = false;
  showVerificationResult = false;
  verificationCode: number = 0;
  preparedVerificationCode: any ;

  sendVerificationCode(): void {
    //Next version will create the code in the server side
    //this.preparedVerificationCode = Math.floor(Math.random() * (1000 - 100 + 1) + 100) //  = this.verificationCode;
    
    let data = {
      "id": this.verificationCode,
      "name": this.activeAccount.name,
      "email": this.activeAccount.username,
      "phone": this.currentPhone,
      "description": this.currentDescription,
      "location": this.currentLocation,
      "whatisAppNumber": this.currentWhatsAppNumber,
      "createdDate": new Date(),
      "approved": false
    };
    this.localhttp.post(`${this.apiUrl}users/createemailcode`, data).subscribe(result => {
    
    this.showModalDisabled = true;
    this.showVerificationInput = true;
    //this.router.navigate(['/admin/settings']);
      
    }, error => console.error(error));

  }

  InsertUpdateUser(user: any) {

    if(this.verificationCode > 0)
    {
      user.Approved = false;
      let data = {
        "id": this.verificationCode,
        "name": this.activeAccount.name,
        "email": this.activeAccount.username,
        "phone": this.currentPhone,
        "whatisAppNumber": this.currentWhatsAppNumber,
        "description": this.currentDescription,
        "location": this.currentLocation,
        "createdDate": new Date(),
        "approved": false
      };
      this.localhttp.post( `${this.apiUrl}users/verify` , data).subscribe(result => {
    
      this.router.navigate(['/admin/settings']);
        
      }, error => {
            console.error(error);
            this.showVerificationInput = false;
            this.showModalDisabled = false;
            this.showVerificationResult = true;
          }
      );
       
    }

  }

  ngOnInit(): void {

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.inProgress$
        .subscribe(() => {            
            this.checkAndSetActiveAccount();
        });
    }

    checkAndSetActiveAccount() {
      
      this.activeAccount = this.authService.instance.getActiveAccount();
      console.log(this.activeAccount.username);
      console.log(this.activeAccount.name);
      this.currentUser = this.activeAccount.username;
      if (!this.activeAccount && this.authService.instance.getAllAccounts().length > 0) {
          let accounts = this.authService.instance.getAllAccounts();         
      }
  }
}

//Interface
interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;
  whatisAppNumber: string;
}
