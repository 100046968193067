import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  uploadImage(imageFile: File, queryParams?: any): Promise<any> {
    const formData = new FormData();
    formData.append('image', imageFile);
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    let params = new HttpParams();
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          params = params.append(key, queryParams[key]);
        }
      }
    }
    const endpoint = `${this.apiUrl}users/uploadprofileimage?email=` + queryParams ; // Replace with your API endpoint

    return this.http.post(endpoint, formData, {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        // Add any authorization headers or other headers required by your API
      }),
      //params: params
    }).toPromise();
  }

  upload(file: File, email: string): Observable<HttpEvent<any>> {
    const headers= new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    const formData: FormData = new FormData();
   
    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.apiUrl}users/uploadprofileimage?email=` + email, formData, {
      reportProgress: true,
      responseType: 'json',
      
    });

    return this.http.request(req);
  }
}
