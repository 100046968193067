<div
  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
>
  <div class="px-6">
    <div class="flex flex-wrap justify-center">
      <div class="w-full px-4 flex justify-center">
        <div class="relative">
          <img
            alt="..."
            src="{{profileImage}}"
            class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
          />
        </div>       
        
      </div>
      <div class="w-full px-4 text-center mt-20">
        <div class="flex items-center space-x-4">
          <input type="file" (change)="onFileSelected($event)" class="py-2 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">
          <button type="button" (click)="onUpload()" class="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-black rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Upload
          </button>
        </div>
        
        <div *ngIf="uploadSuccess" class="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg">
          <p>Image uploaded successfully!</p>
        </div>
      </div>
      <div class="w-full px-4 text-center mt-20">
        <div class="flex justify-center py-4 lg:pt-4 pt-8">
          <div class="mr-4 p-3 text-center">
            <span
              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
            >
              22
            </span>
            <span class="text-sm text-blueGray-400">Liked Videos</span>
          </div>
          <div class="mr-4 p-3 text-center">
            <span
              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
            >
              10
            </span>
            <span class="text-sm text-blueGray-400">Added Comments</span>
          </div>
          <div class="lg:mr-4 p-3 text-center">
            <span
              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
            >
              {{currentDonation}}
            </span>
            <span class="text-sm text-blueGray-400">Donations</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-12">
      <h3
        class="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
      >
        {{currentName}}
      </h3>
      <div
        class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
      >
        <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
        {{currentLocation}}
      </div>
      <div class="mb-2 text-blueGray-600 mt-10">
        <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
        SSC Khaatumo nonprofit - Valuable Donor
      </div>
      <div class="mb-2 text-blueGray-600">
        <i class="fas fa-university mr-2 text-lg text-blueGray-400"></i>
        Supporting orphans, disabled and providing health care
      </div>
    </div>
    <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
      <div class="flex flex-wrap justify-center">
        <div class="w-full lg:w-9/12 px-4">
          <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
            {{currentDescription}}
          </p>
          <a href="javascript:void(0);" class="font-normal text-red-600">
            Show more
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
