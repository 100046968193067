import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card-table",
  templateUrl: "./card-table.component.html",
})
export class CardTableComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor() {}

  ngOnInit(): void {}
 
  showModalHealthcare = false;
  showModalOrphanes = false;
  showModalDisabled = false;
  toggleModal(model: string){
    if(model == "healthcare"){
      this.showModalHealthcare = !this.showModalHealthcare;
    }
    if(model == "orphans"){
      this.showModalOrphanes = !this.showModalOrphanes;
    }
    if(model == "disabled"){
      this.showModalDisabled = !this.showModalDisabled;
    }
  }
}
