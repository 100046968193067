import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit, ViewChild,  AfterViewInit,  ElementRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
 

@Component({
 
  
  selector: "app-login",
  templateUrl: "./login.component.html",
  
})
export class LoginComponent implements OnInit {
  
  
  constructor(private titleService:Title) {
    this.titleService.setTitle("Login Page");
  }

  ngOnInit(): void { 
     
  }

}