import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CallClient, CallAgent, CallEndReason, CallState, ConnectionState   } from "@azure/communication-calling";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-friends",
  styleUrls: [ './friends.component.css' ],
  templateUrl: "./friends.component.html",
})

export class FriendsComponent implements OnInit {
  friendsfromservice: any[] = [];
  myfriendsfromservice: any[] = [];
  searchQuery: string = '';  
  filteredFriends: any[] = [];
  myfilteredFriends: any[] = [];
  textShow: boolean = false;
  isLoading = false;
  callInProgress: boolean = false;
  private callTimeoutId: number; // Timeout ID for ending the call
  private apiUrl = environment.apiUrl;
  private connectionString: string = "endpoint=https://communicationssckhaatumononprofit.unitedstates.communication.azure.com/;accesskey=8Sx+uFV965Li89UtjJhwzuiecJhGQRuZlL/Wwr2jLP47V4wzikYPIZNqkOTiZvv9o6XT6Y0Fp6blCmLpdTGJZw==";
  private callAgent: CallAgent | undefined;
  private call: any; // This will hold the active call object
 

  async ngOnInit(): Promise<void>  {
    await this.initializeCallAgent();
    
  }

  private async initializeCallAgent(): Promise<void> {
    try {
      const identityClient = new CommunicationIdentityClient(this.connectionString);
      const { token } = await identityClient.createUserAndToken(["voip"]);
      this.callAgent = await new CallClient().createCallAgent(
        new AzureCommunicationTokenCredential(token)
      );
      
      this.callAgent.on('callsUpdated', (e) => {
        e.added.forEach((call) => {
          this.call = call;
          
        });
      });
      
     
    } catch (error) {
      console.error('Error initializing call agent:', error);
    }
  }
// Subscribe to a call obj.
// Listen for property changes and collection udpates.
subscribeToCall = (call) => {
  try {
      // Inspect the initial call.id value.
      console.log(`Call Id: ${call.id}`);
      // Set timeout for call end after 2 minutes (120,000 milliseconds)
      this.callTimeoutId = window.setTimeout(() => {
        this.endCall('Timeout'); // End call after timeout
      }, 40000); // 2 minutes in milliseconds


      //Subsribe to call's 'idChanged' event for value changes.
      call.on('idChanged', () => {
          console.log(`Call Id changed: ${call.id}`); 
      });

      // Inspect the initial call.state value.
      console.log(`Call state: ${call.state}`);
      // Subscribe to call's 'stateChanged' event for value changes.
      call.on('stateChanged', async () => {
          console.log(`Call state changed: ${call.state}`);
          if(call.state === 'Connected') {
              
          } else if (call.state === 'Disconnected') {
              this.callInProgress = false;
              this.clearCallTimeout();
              console.log(`Call ended, call end reason={code=${call.callEndReason.code}, subCode=${call.callEndReason.subCode}}`);
          }   
      });

      
  } catch (error) {
      console.error(error);
  }
}
  public async startCall(friend: any): Promise<void> {
    if (!this.callAgent) {
      console.error('Call agent is not initialized.');
      return;
    }
    this.callInProgress = true;
    this.isLoading = true;
    try {
      const call = this.callAgent.startCall(
        [{  phoneNumber: friend.phone }],
        { alternateCallerId: { phoneNumber: "+18333627691" } }
      );
      this.subscribeToCall(call);
    } catch (error) {
      console.error('Error starting call:', error);
    }
    this.isLoading = false;
    
  }

  async endCall(reason: string) {
    if (this.call) {
      this.call.hangUp({ forEveryone: true }); // End the call for everyone
      this.callInProgress = false;
      console.log('endCall ' + reason);
    }
    this.clearCallTimeout();
  }

  clearCallTimeout() {
    if (this.callTimeoutId) {
      window.clearTimeout(this.callTimeoutId);
      this.callTimeoutId = null;
      console.log('clearCallTimeout in 50 seconds');
    }
    
    console.log('clearCallTimeout in 50 seconds outside');
  }
  callFriend(phone: string) {
    alert(`Calling ${phone}...`);
  }

  textFriend(phone: string) {
    alert(`Texting ${phone}...`);
  }



  constructor(private titleService:Title, public http: HttpClient, 
    private router: Router, private authService: MsalService) {
    this.titleService.setTitle("Friends Page");
    
    this.http.get<Contact[]>(`${this.apiUrl}users` ).subscribe(result => {
      
      this.friendsfromservice = result.sort((a, b) => (a.id > b.id) ? -1 : 1);//.filter(x => x.approved == true );       
      this.http.get<Contact[]>( `${this.apiUrl}friends/`  + this.authService.instance.getActiveAccount()?.username).subscribe(result => {
      
        this.myfriendsfromservice = this.friendsfromservice.filter(friend => 
          result.some(contact => contact.email === friend.email));
          this.filteredFriends = this.friendsfromservice.filter(friend => 
            result.some(contact => contact.email !== friend.email));  
          this.myfilteredFriends = this.myfriendsfromservice;     
          this.friends = this.filteredFriends;
          this.myfriends = this.myfilteredFriends;
      });
    });
    
    
  }  

  private friends = []; 
  private myfriends = []; 


  search(): void {
    // Filter friends based on the search query
    this.filteredFriends = this.friends.filter(friend =>
      friend.name && friend.name.trim() !== '' && 
      friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  //SendingSMS Modal
  isModalOpenWhatsApp = false;
  textareaValueWhatsApp:string = '';

  openModalWhatsApp(friend: any) {
    this.isModalOpenSms = true;
  }

  closeModalWhatsApp() {
    this.isModalOpenSms = false;
    this.textareaValueWhatsApp = ''; // Reset textarea value when closing modal
  }

  submitFormWhatsApp() {
    this.isLoading = true;
    // Handle form submission logic here
    console.log('Textarea value:', this.textareaValueWhatsApp);
    this.closeModalWhatsApp(); // Close modal after submission (you can modify this behavior as needed)
    this.isLoading = false;
  }
  //SendingSMS Modal
  isModalOpenSms = false;
  textareaValueSms: string = '';

  openModalSms(friend: any) {
    this.isModalOpenSms = true;
  }

  closeModalSms() {
    this.isModalOpenSms = false;
    this.textareaValueSms = ''; // Reset textarea value when closing modal
  }

  submitFormSms() {
    this.isLoading = true;
    // Handle form submission logic here
    console.log('Textarea value:', this.textareaValueSms);
    this.http.get<any[]>( `${this.apiUrl}openai/sendsms?text=` + this.textareaValueSms + '&fromPhone='+
      this.authService.instance.getActiveAccount()?.username +'&toPhone='+ +'7816668850').subscribe(result => {
      this.textareaValueSms = result.toString(); 
      this.isLoading = false;
    });
    this.closeModalSms(); // Close modal after submission (you can modify this behavior as needed)
    this.isLoading = false;
  }

  connect(friend: any) {
    //Need to implement
  }
}
interface Contact {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;
  whatisAppNumber: string;
}
