import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
@Component({
  styleUrls: [ './footer.component.css' ],
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  date = new Date().getFullYear();
  constructor(private localhttp: HttpClient) {}

  ngOnInit(): void {}




  messageInput: string = '';
  messages: string[] = [];
  isLoading: boolean = false;
  
  @ViewChild('messagesDisplay') messagesDisplay!: ElementRef;

  async sendMessage(): Promise<void> {
    if (this.messageInput.trim() !== '') {
      this.isLoading = true;
      this.localhttp.get(`${this.apiUrl}openai/message?message=` + this.messageInput).subscribe(result => {
      console.log(result);       
      this.messages.push(result.toString());
      this.messageInput = '';
      this.isLoading = false;
      setTimeout(() => this.scrollToBottom(), 0);
      }, error => {
            console.error(error);
      });
      
    }
  }

  private scrollToBottom(): void {
    if (this.messagesDisplay) {
      this.messagesDisplay.nativeElement.scrollTop = this.messagesDisplay.nativeElement.scrollHeight;
    }
  }
}
