<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
      >
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">Associate your acount with your donation</h6>
          </div>
          
          <hr class="mt-6 border-b-1 border-blueGray-300" />
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="text-blueGray-400 text-center mb-3 font-bold">
            <small>
              Associating your email with your phone number adds an extra layer of security to your account. 
              It helps us in several ways, including account recovery, communication about important updates, 
              and ensuring that you are the rightful owner of the account and any associated donations.
            </small>
          </div>
          <form #userlogin = "ngForm" (ngSubmit) = "InsertUpdateUser(userlogin.value)">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"                
                name="Email"
                [(ngModel)]="currentUser"
                autocomplete="off"
                readonly
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Phone Number
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Phome Number"               
                name="PhomeNumber"
                [(ngModel)]="currentPhone"
              />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Whatsapp Number
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Whatsapp Number"               
                name="WhatisAppNumber"
                [(ngModel)]="currentWhatsAppNumber"
              />
            </div>
            
            <div *ngIf="showVerificationInput" class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Enter Verification Code
              </label>
              <input
                type="text"
                name="Location"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="location" 
                [(ngModel)]="verificationCode"
              />
            </div>
            <div *ngIf="showModalDisabled">
              Check your text or whatsapp for a verification message.<br>
              Enter the code in the input box above.<br>
              
            </div>
            <div *ngIf="showVerificationResult" >
              Invalid Code.<br> Try requesting code again.<br><br>
            </div>
            
            <div *ngIf="!showVerificationInput" class="relative w-full mb-3">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button"
                (click)="sendVerificationCode()"
              >
                Send Verification Code
              </button>
            
            </div>

            
            <div *ngIf="showVerificationInput">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
                [disabled]="!showModalDisabled">
                submit
              </button>
             
            </div>
           
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
