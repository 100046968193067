import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { createClaimsTable } from '../utils/claim-utils';
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-index",
  
  templateUrl: "./index.component.html",
})
export class IndexComponent implements OnInit {
    public forecasts: WeatherForecast[] = [];
    title = 'Microsoft identity platform';
    
    loginDisplay = false;
    displayedColumns: string[] = ['claim', 'value', 'description'];
    dataSource: any = [];

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, 
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private titleService:Title) {
      this.titleService.setTitle("Home Page");
    }
    
  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
            })

            
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
      if (claims) {
          const claimsTable = createClaimsTable(claims);
          this.dataSource = [...claimsTable];
      }
  }

}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}