import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
})
export class TermsComponent implements OnInit {
 
  constructor(private titleService:Title) {
    this.titleService.setTitle("Terms Page");
  }

  ngOnInit() {}
   
}
