import { HttpClient } from "@angular/common/http";
import { Component, AfterViewInit, ViewChild, ElementRef, Input } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-table-dropdown",
  templateUrl: "./table-dropdown.component.html",
})
export class TableDropdownComponent implements AfterViewInit {
  private apiUrl = environment.apiUrl;
  dropdownPopoverShow = false;
  @Input() videoId: any;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  selectedComment = false;
  selectedFile:any;
  currentMsgToParent = '';
  constructor(private http: HttpClient) { }

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }
  approveDocument(filename:any){
    const userapprove: UserDocument = {
      id:  0,
      fileData: "john.doe@example.com",
      fileType: true,
      approved:true,
      fileName: filename,
      createdDate: null
      // Add other properties as needed
    };
    
    // Convert the object to a JSON string
    const jsonData: string = JSON.stringify(userapprove);
    
    this.http.post(`${this.apiUrl}docs/approve`, userapprove).subscribe(result => {
      console.warn("PASSED")
      this.dropdownPopoverShow = false
    }, error => console.error(error)); 
  }
  addComment() : void{
    console.warn(this.selectedFile);
    const userapprove: UserDocument = {
      id:  0,
      fileData: this.currentMsgToParent,
      fileType: true,
      approved:true,
      fileName: this.selectedFile,
      createdDate: null
      // Add other properties as needed
    };
    
    // Convert the object to a JSON string
    const jsonData: string = JSON.stringify(userapprove);
    
    this.http.post(`${this.apiUrl}docs/update` , userapprove).subscribe(result => {
      console.warn("PASSED")
      this.dropdownPopoverShow = false
    }, error => console.error(error)); 
    this.dropdownPopoverShow = false
    this.selectedComment = false;
  }
  deleteFile(filename:any) : void{
    console.warn(this.selectedFile);
    const userapprove: UserDocument = {
      id:  0,
      fileData: this.currentMsgToParent,
      fileType: true,
      approved:true,
      fileName: filename,
      createdDate: null
      // Add other properties as needed
    };
    
    // Convert the object to a JSON string
    const jsonData: string = JSON.stringify(userapprove);
    
    this.http.post(`${this.apiUrl}docs/delete` , userapprove).subscribe(result => {
      console.warn("PASSED")
      this.dropdownPopoverShow = false
    }, error => console.error(error)); 
    this.dropdownPopoverShow = false
    this.selectedComment = false;
  }
  cancelComment() : void{
    console.warn(this.selectedFile);
    this.dropdownPopoverShow = false
    this.selectedComment = false;
  }
  openComment(filename:any) : void{
    this.selectedComment = true;
    this.selectedFile = filename;
    this.dropdownPopoverShow = false
  }
}


interface UserDocument {
  fileName: string,
  fileData: string,
  fileType: true,
  id: number;
  createdDate: any;
  approved: any;
}