import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ReferrerService } from "./referrer.service";
import { environment } from "src/environments/environment";
@Component({
  styleUrls: [ './landing.component.css' ],
  selector: "app-landing",
  templateUrl: "./landing.component.html",
})
export class LandingComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  localhttp: HttpClient;
  @Input('userEmail')  userEmail: string;
  langSelect: string;
  constructor(public translate: TranslateService, 
    private titleService:Title,
    http: HttpClient, private apiService: ReferrerService) {
    translate.addLangs(['en', 'so', 'uk']);
    this.langSelect = localStorage.getItem('langSelect') || 'en';
    translate.setDefaultLang(this.langSelect);
    translate.currentLang = this.langSelect;
   //translate.setDefaultLang('en');
    this.titleService.setTitle("SSC Non-Profit");
    const browserLang = translate.getBrowserLang();
    translate.use(this.langSelect);
    this.localhttp = http;
  }
  home!: { heroMain: string; heroBody: string; };
  heroMain!: string;
  heroBody!: string;
  welcome!: string;

  isShown: boolean = false 
  isLoading: boolean = false;
  ngOnInit(): void {

  this.heroMain = this.translate.instant('landing.heroMain');

  // hardcoded example
   
  this.translate.get(['landing.heroMain', 'landing.heroBody'])
    .subscribe(translations => {
      this.heroMain = translations['landing.heroMain'];
      this.heroBody = translations['landing.heroBody'];
    });
    var referrerUrl = document.referrer;    
    this.apiService.sendDataFromExternalReferrer(referrerUrl);
  }

  subscribe(email) {
    console.log('ivideod is equltto', email);
    this.isLoading = true;
    console.log('ivideod', this.userEmail);
    this.localhttp.get(`${this.apiUrl}subscribtion/email?email=` + email, email).subscribe(result => {
      this.isShown = true;
      this.userEmail = email;
      console.log('added email')
      this.isLoading = false;
    }, error => console.error(error));    

  }
  voiceCallOptIn: boolean = false;
  smsOptIn: boolean = false;
  emailOptIn: boolean = false;
  isOptin: boolean = false;
  optIn() {
    console.log('voiceCallOptIn');
    console.log(this.voiceCallOptIn);
    console.log(this.smsOptIn);
    console.log(this.emailOptIn);
    if(this.userEmail == null) {
      console.log('email is null');
      this.isOptin = true
      return;
    }
    this.isLoading = true;
    this.localhttp.get( `${this.apiUrl}subscribtion/optin?email=`
    + this.userEmail 
    + "&voiceCallOptIn=" + this.voiceCallOptIn
    + "&smsOptIn=" + this.smsOptIn
    + "&emailOptIn=" +this.emailOptIn).subscribe(result => {
      this.isShown = true;
      console.log('added email')
      this.isLoading = false;
    }, error => console.error(error));   
  }
}
