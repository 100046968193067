

<div class="container mx-auto px-4 py-40 h-full">
  
  
  <div class="container mx-auto p-4">
    <div class="grid grid-cols-2 gap-4">      
      <div *ngFor="let friend of myfilteredFriends" class="bg-zinc-400 rounded-lg shadow-lg overflow-hidden flex">
        <div class="flex justify-center mt-4">
          <img src="{{ friend.imageUrl }}" alt="{{ friend.name }}" class="w-24 h-24 rounded-full">
        </div>
        <div class="p-6">
          <h2 class="text-xl font-semibold text-gray-800">{{ friend.name }}</h2>
          <p class="text-gray-600">{{ friend.location }}</p>
          <p class="text-gray-600">{{ friend.description }}</p>
          <div class="mt-4">
            <div class="flex justify-around">
              <button (click)="startCall(friend)" class="rounded-full px-4 py-2 bg-red-500 text-white hover:bg-red-600">Call</button>
              <!-- Call in progress dialog -->
              <app-progress-dialog *ngIf="callInProgress"></app-progress-dialog>
              <button (click)="openModalWhatsApp(friend)" class="rounded-full px-4 py-2 bg-red-500 text-white hover:bg-red-600">Text</button>
              <button (click)="openModalSms(friend)" class="rounded-full px-4 py-2 bg-red-500 text-white hover:bg-red-600">WhatsApp</button>              
            </div>
            <div *ngIf="isLoading" class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- Border with Background Color -->
<div class="bg-red-600 h-24 w-full my-4"></div>
  <div class="flex items-center mb-4">
    <input type="text" [(ngModel)]="searchQuery" class="rounded-l-lg px-4 py-2 border-t border-b border-l text-gray-800 border-gray-200 bg-white" placeholder="Search...">
    <button (click)="search()" class="rounded-r-lg px-4 py-2 border border-gray-300 bg-blue-500  hover:bg-blue-600">Search</button>
  </div>
  
  <div class="container mx-auto p-4">
    <div class="grid grid-cols-2 gap-4">
      <div *ngFor="let friend of filteredFriends" class="bg-white rounded-lg shadow-lg overflow-hidden flex">       
        <div class="flex justify-center mt-4">
          <img src="{{ friend.imageUrl }}" alt="{{ friend.name }}" class="w-24 h-24 rounded-full">
        </div>
        <div class="p-6 ml-8">
          
          <div *ngIf="friend.description?.trim(); else generalName">
            <h2 class="text-xl font-semibold text-gray-800">{{ friend.name }}</h2>
          </div>
          <div *ngIf="friend.description?.trim(); else generalLocation">
            <p class="text-gray-600">{{ friend.location }}</p>
          </div>
          <div *ngIf="friend.description?.trim(); else generalMessage">
            <p class="text-gray-600">{{ friend.description }}</p>
          </div>
          
          <div class="mt-4">
            <div class="flex justify-around">
              <button (click)="connect(friend)" class="rounded-full px-4 py-2 bg-red-500 text-white hover:bg-red-600">Connect</button>                        
            </div>
            <div *ngIf="isLoading" class="spinner"></div>
          </div>
        </div>
      </div>     
    </div>
  </div>
  <!-- Modal -->
  <div *ngIf="isModalOpenSms" class="fixed inset-0 flex items-center justify-center z-10 overflow-auto bg-gray-900 bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-lg max-w-md w-full">
      <!-- Modal header -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold text-gray-800">Send Text</h2>
        <button class="text-gray-500 hover:text-gray-700" (click)="closeModalSms()">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <!-- Modal content: textarea and submit button -->
      <form #userSms = "ngForm" (ngSubmit)="submitFormSms()">
        <textarea class="w-full border rounded p-2 mb-4" rows="4" placeholder="Enter text..." 
        id="textareaSms" name="textareaSms" [(ngModel)]="textareaValueSms"></textarea>
        <button type="submit" class="bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded">
          Submit
        </button><div *ngIf="isLoading" class="spinner"></div>
      </form>
    </div>
  </div>
  <!-- Modal -->
  <div *ngIf="isModalOpenWhatsApp" class="fixed inset-0 flex items-center justify-center z-10 overflow-auto bg-gray-900 bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-lg max-w-md w-full">
      <!-- Modal header -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold text-gray-800">Send WhatsApp</h2>
        <button class="text-gray-500 hover:text-gray-700" (click)="closeModalWhatsApp()">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <!-- Modal content: textarea and submit button -->
      <form #userwApp= "ngForm" (ngSubmit)="submitFormWhatsApp()">
        <textarea class="w-full border rounded p-2 mb-4" rows="4" placeholder="Enter text..." [(ngModel)]="textareaValueWhatsApp"></textarea>
        <button type="submit" class="bg-blue-500 hover:bg-blue-600  font-bold py-2 px-4 rounded">
          Submit
        </button><div *ngIf="isLoading" class="spinner"></div>
      </form>
    </div>
  </div>  
 
</div>
<ng-template #generalMessage>
  <div>
    description: "                      "
  </div>
</ng-template>
<ng-template #generalName>
  <div>
    Name:                    
  </div>
</ng-template>
<ng-template #generalLocation>
  <div>
    Location:               
  </div>
</ng-template>