import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { TranslateService } from "@ngx-translate/core";
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EventService } from "src/app/services/event.service";
import { environment } from "src/environments/environment";

declare function makeDonation(argm1:string): void;
@Component({
    styleUrls: [ './auth-navbar.component.css' ],
    selector: "app-auth-navbar",
    templateUrl: "./auth-navbar.component.html",
})
export class AuthNavbarComponent implements OnInit {
  navbarOpen = false;
  en = false;
  so = false;
  title = 'Microsoft identity platform';
  isIframe = false;
  loginDisplay = false;
  user!: { firstName: string; lastName: string; };
  private readonly _destroying$ = new Subject<void>();
  welcome!: string;
  usernameLabel!: string;
  passwordLabel!: string;
  routeDonation: string;
  private apiUrl = environment.apiUrl;
  astronauts = ['Lovell', 'Swigert', 'Haise'];
  history: string[] = [];
  missions = ['Fly to the moon!',
              'Fly to mars!',
              'Fly to Vegas!'];
  nextMission = 0;
  
  constructor(private el: ElementRef, public translate: TranslateService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService,
  private route: ActivatedRoute,
  private missionService: EventService
  ) {
    translate.addLangs(['en', 'so', 'uk']);
    this.langSelect = localStorage.getItem('langSelect') || 'en';
    translate.setDefaultLang(this.langSelect);
    translate.currentLang = this.langSelect;
    
    if(this.langSelect == 'en'){
        this.en = true;
        this.so = false;
    }
    else{
        this.so = true;
        this.en = false;
    }
    const browserLang = translate.getBrowserLang();
    translate.use(this.langSelect);
  
    missionService.missionConfirmed$.subscribe(
        astronaut => {
          this.history.push(`${astronaut} confirmed the mission`);
        });
  }

  @ViewChild('draggable', { read: ElementRef, static:false }) draggable: ElementRef;
  ngOnInit(): void {    
    makeDonation( `${this.apiUrl}donations` );
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */

    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
            if (this.authService.instance.getAllAccounts().length === 0) {
                window.location.pathname = "/";
            } else {
                this.setLoginDisplay();
            }
        });

    this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this._destroying$)
        )
        .subscribe(() => {
            this.setLoginDisplay();
            this.checkAndSetActiveAccount();
        });
        const firstParam: string = this.route.snapshot.queryParamMap.get('donation');
        if(firstParam != null){ 
          this.routeDonation = firstParam;
        }
  }

  ngAfterViewInit(): void {
    this.draggable.nativeElement.classList.add('hidden')
    
    if(this.routeDonation != null){ 
        console.log('routeDonation', this.routeDonation)
        this.toggleModal();
      }
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }
  showModal = true;
  toggleModal(){  
    this.showModal = !this.showModal;   
    this.setNavbarOpen();
    //setTimeout(() => {
        window.scroll(0,0);
      if(!this.showModal) {
        this.draggable.nativeElement.classList.remove('hidden')
         
      }
      else {
        this.draggable.nativeElement.classList.add('hidden')
        console.log('added hidden')
      }
    //}, 100);
    
  }
   
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
}

checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        // add your code for handling multiple accounts here
        this.authService.instance.setActiveAccount(accounts[0]);
    }
}

login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
    } else {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }
}

logout() {
    const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.authService.logoutPopup({
            account: activeAccount,
        });
    } else {
        this.authService.logoutRedirect({
            account: activeAccount,
        });
    }
}
langSelect: string;
saveLang(lang) {
    // Save data to Local Storage
    this.translate.use(lang)
    localStorage.setItem('langSelect', lang);
    if(lang == 'en'){
        this.en = true;
        this.so = false;
    }
    else{
        this.so = true;
        this.en = false;
    }
    this.announce(lang);
    //window.location.reload();
  } 
  
  announce(lang:string) {
    let mission = this.missions[this.nextMission++];
    this.missionService.announceMission(lang);
    this.history.push(`Mission "${mission}" announced`);
    if (this.nextMission >= this.missions.length) { this.nextMission = 0; }
  }
}
