import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { ImageUploadService } from './card-profile.services'; 
import { environment } from "src/environments/environment";
@Component({
  selector: "app-card-profile",
  templateUrl: "./card-profile.component.html",
})
export class CardProfileComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  selectedFile: File | null = null;
  uploadSuccess = false;

  constructor(http: HttpClient,
    
    private ref: ChangeDetectorRef, 
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private imageUploadService: ImageUploadService
    ) {
   
    http.get<User>(`${this.apiUrl}users/email?email=` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {
      this.Users = result;
      console.log(this.Users);
      this.currentUser = this.Users.email;  
      this.currentName = this.Users.name;          
      this.currentDescription = this.Users.description;
      this.currentPhone = this.Users.phone;
      this.currentLocation = this.Users.location;
      this.profileImage = this.Users.imageUrl;
      //Call to get the total donation amount
      http.get<Donation[]>(`${this.apiUrl}donations/email?email=` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {
          
        result.forEach(element => {
          this.amount += element.amount;
          console.log(element.amount);
          console.log(this.amount);
        });
        
        
        this.currentDonation = this.amount;
       
    }, error => console.error(error));  

    
    }, error => console.error(error));    
  }
  currentUser: string;
  currentPhone: string;
  currentLocation: string;
  currentDescription: string;
  currentName: string;
  currentDonation: number = 0;
  public Users: User;
  activeAccount: any;
  amount: number = 0;
  progress = 0;
  message = '';
  profileImage = '';
  ngOnInit(): void {}

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] as File;   
  }

  onUpload(): void {
    if (this.selectedFile) {

        this.imageUploadService.upload(this.selectedFile, this.authService.instance.getActiveAccount()?.username).subscribe({
          next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                  this.progress = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
                  this.message = event.body.message;                 
              }
          },
          error: (err: any) => {
              console.log(err);
              this.progress = 0;

              if (err.error && err.error.message) {
                  this.message = err.error.message;
              } else {
                  this.message = 'Could not upload the file!';
              }

              this.selectedFile = undefined;
          }
      });
  
    }
  }
}
interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;

}
interface Donation {
  id: number;
  transactionId: string;
  phone: string;
  email: string;
  createdDate: any;
  approved: any; 
  amount: any;
  comment: any;
 
}