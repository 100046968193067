 
import { Component, Input, OnInit } from "@angular/core";
import {Title} from "@angular/platform-browser";
@Component({
  selector: "app-files",
  templateUrl: "./files.component.html",
})
export class FileUploadComponent implements OnInit {
 
   
  constructor(private titleService:Title) {
    this.titleService.setTitle("File Upload Page");
  }
  ngOnInit(): void {}

  showModal = true;
  toggleModal(){
    this.showModal = !this.showModal;
  }

}
