 
    <div class="px-4 md:px-10 mx-auto w-full m-24">
      <section class="container mx-auto my-8 p-8">
        <h1 class="text-4xl font-bold mb-6">About Us</h1>
        <p class="text-lg mb-4">
            Welcome to SSC Khaatumo Non-Profit, where our mission is to make a positive impact on the community.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <p class="text-lg mb-4">
            We are dedicated to providing support and resources to those in need. Our team is committed to creating a better
            future for everyone through various initiatives and projects.
        </p>
        <p class="text-lg mb-4">
          If you share our passion for making a difference, consider joining us in
          our mission. Contact us to learn more about volunteering opportunities and
          how you can contribute to our cause.
      </p>
        <!-- Add more content as needed -->
      </section>
      <app-footer-admin></app-footer-admin>
    </div>
 
 

