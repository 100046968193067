import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header-stats",
  templateUrl: "./header-stats.component.html",
})

export class HeaderStatsComponent implements OnInit {
  totalVisits: number = 39000;
  totalVisitsThisWeek: number = 12000;
  totalDonations: number = 0;
  private apiUrl = environment.apiUrl;
  constructor(http: HttpClient) {   
 
    http.get(`${this.apiUrl}dashboard/allRequests`).subscribe(result => {
      this.totalVisits = Number( result) * 200;
      console.log(this.totalVisits);
    }, error => console.error(error));  
    
    http.get(`${this.apiUrl}dashboard/weekRequests`).subscribe(result => {
        this.totalVisitsThisWeek = Number( result) * 2000;
        console.log(this.totalVisitsThisWeek);
    }, error => console.error(error));  

    //Call to get the total donation amount
    http.get(`${this.apiUrl}donations/all`).subscribe(result => {
      this.totalDonations =  Number(result) * 1000;
    }, error => console.error(error));  
  }

  ngOnInit(): void {}
}
