import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";


declare var paypal;
@Component({
  selector: "app-tables",
  templateUrl: "./tables.component.html",
})
export class TablesComponent implements OnInit {
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef
  constructor(private titleService:Title) {
    this.titleService.setTitle("Tables Page");
  }
  product = {
    price: 77,
    desciption: 'used coach',
    img: 'asset/img/angular.png'
  };
  paidFor = false;

  ngOnInit() {
    paypal
      .Buttons(
        {
          createOrder: (data, actions) =>{
            return actions.order.create(
              {
                purchase_units: [{
                  desription: this.product.desciption,
                  amount: {
                    currency_code: 'USD',
                    value: this.product.price
                  }
                }]
              }
            );
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paidFor = true;
            console.log(order);
          },
          onError: err => {
            console.log(err);
          }
        })
      .render(this.paypalElement.nativeElement)
  }
  
}
