
import { Title } from "@angular/platform-browser";

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CityDataService } from '../../../city-data.service';
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CallClient, CallAgent } from "@azure/communication-calling";
declare var google: any;

@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
})
export class MapsComponent implements OnInit {
  private connectionString: string = "endpoint=https://communicationssckhaatumononprofit.unitedstates.communication.azure.com/;accesskey=8Sx+uFV965Li89UtjJhwzuiecJhGQRuZlL/Wwr2jLP47V4wzikYPIZNqkOTiZvv9o6XT6Y0Fp6blCmLpdTGJZw==";
  private callAgent: CallAgent | undefined;

 

  
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  map: any;
  cities: any[];
  polyline: any[];

  constructor(private cityDataService: CityDataService, private titleService:Title) { 
    this.titleService.setTitle("Maps Page");
  }

  /*ngOnInit() {
    this.cityDataService.getCitiesData().subscribe(data => {
      this.cities = data;
      this.initMap();
      this.addMarkers();
      this.connectMarkers();
      
    });
    
  }*/
  async ngOnInit(): Promise<void> {
    await this.initializeCallAgent();
    this.cityDataService.getCitiesData().subscribe(data => {
      this.cities = data;
      this.initMap();
      this.addMarkers();
      this.connectMarkers();
      
    });
  }

  private async initializeCallAgent(): Promise<void> {
    try {
      const identityClient = new CommunicationIdentityClient(this.connectionString);
      const { token } = await identityClient.createUserAndToken(["voip"]);
      this.callAgent = await new CallClient().createCallAgent(
        new AzureCommunicationTokenCredential(token)
      );
    } catch (error) {
      console.error('Error initializing call agent:', error);
    }
  }

  public async startCall(): Promise<void> {
    if (!this.callAgent) {
      console.error('Call agent is not initialized.');
      return;
    }

    try {
      const call = this.callAgent.startCall(
        [{ phoneNumber: "+17816668850" }],
        { alternateCallerId: { phoneNumber: "+18333627691" } }
      );
    } catch (error) {
      console.error('Error starting call:', error);
    }
  }
  initMap() {
    const mapProperties = {
      center: { lat:  8.5097406, lng: 47.4280259 },
    zoom: 8,
    //mapTypeId: google.maps.MapTypeId.,
      //center: new google.maps.LatLng(5.152149, 46.199616), // Center of Somalia
      //zoom: 8,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
  }

  addMarkers() {
    this.cities.forEach(city => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(city.latitude, city.longitude),
        map: this.map,
        title: city.name
      });

      // Add click event listener to each marker
      marker.addListener('click', () => {
        this.openInfoWindow(marker, city);
      });
    });
  }

  openInfoWindow(marker: any, city: any) {
    const contentString = `
      <div>
        <h2>${city.name}</h2>
        <p>Latitude: ${city.latitude}</p>
        <p>Longitude: ${city.longitude}</p>
        <img src="${city.imageUrl}" >
      </div>
    `;

    const infoWindow = new google.maps.InfoWindow({
      content: contentString
    });

    infoWindow.open(this.map, marker);
  }

  connectMarkers() {
    this.cityDataService.getPolylineData().subscribe(data => {     
      this.polyline = data;        
      for (let i = 0; i < this.polyline.length - 1; i++) {
        const city1 = this.polyline[i];
        const city2 = this.polyline[i + 1];
  
        const lineCoordinates = [
          { lat: city1.latitude, lng: city1.longitude },
          { lat: city2.latitude, lng: city2.longitude }
        ];
  
        const line = new google.maps.Polyline({
          path: lineCoordinates,
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2
        });
  
        line.setMap(this.map);
      }
    });
    
  }
    
}
