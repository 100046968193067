 
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-progress-dialog",
  templateUrl: "./progress-dialog.component.html",
})
export class ProgressDialog implements OnInit {
 
  constructor() {
  }

  ngOnInit(): void {}

}
