import { HttpClient } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { Console } from "console";
import { Subscription } from "rxjs";
import { EventService } from "src/app/services/event.service";
import { environment } from "src/environments/environment";

@Component({
  styleUrls: [ './doc.component.css' ],
  selector: "app-doc",
  templateUrl: "./doc.component.html",
  
})
export class DocumentComponent implements OnInit, OnDestroy {
  private apiUrl = environment.apiUrl;
  public documents: CloudDocument[] = [];
  localhttp: HttpClient;
  isShown: boolean = false 
  langSelect: string;
  @Input() astronaut: string;
  mission = '<no mission announced>';
  confirmed = false;
  announced = false;
  subscription: Subscription;
  @Input('userEmail')  userEmail: string;
  isLoading: boolean = true;
  constructor(http: HttpClient, private titleService:Title,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute,
    private missionService: EventService) {
    this.titleService.setTitle("File Display Page");
    this.isImage = true;
    this.isLoading = true;
    this.currentDocument = `${this.apiUrl}doc` ;
    this.localhttp = http;
    this.langSelect = localStorage.getItem('langSelect') || 'en';
    http.get<CloudDocument[]>(`${this.apiUrl}docs/` + this.langSelect).subscribe(result => {
      if(this.langSelect == 'en'){
        this.documents = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true );
      }
      else{
        this.documents = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true );
      }
      this.documents.forEach( (element) => {
        element.fileName = element.fileName.substring(element.fileName.lastIndexOf('/')+1);
    });

    if(this.routeDocument != null){ 
      
      this.clickShow(this.routeDocument);
    }
    }, error => console.error(error));      
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        this.mission = mission;
        this.announced = true;
        this.confirmed = false;
        http.get<CloudDocument[]>(`${this.apiUrl}docs` + this.mission).subscribe(result => {
      if(this.langSelect == 'en'){
        this.documents = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true );
      }
      else{
        this.documents = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true );
      }
      this.documents.forEach( (element) => {
        element.fileName = element.fileName.substring(element.fileName.lastIndexOf('/')+1);
    });
    this.isLoading = false;
     
    }, error => console.error(error)); 
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  confirm() {
    this.confirmed = true;
    this.missionService.confirmMission(this.astronaut);
  }
  public  currentDocument: string ;
  public  routeDocument: string;
  public isDoc: boolean = false;
  public isImage: boolean = false;
  currentDocId: string;
  ngOnInit(): void {
    const firstParam: string = this.route.snapshot.queryParamMap.get('Id');
    if(firstParam != null){ 
      this.routeDocument = firstParam;
    }
    this.currentDocument = `${this.apiUrl}doc/StaticFiles/Captureslserver%20broker%20claim.PNG` ;
  }
  notificationHandler(v){
    console.log("called notificationHandler");
  }
  showModal = false;
  showModal2 = false;
  toggleModal(){
    this.showModal = !this.showModal;
  }
  clickShow(id: any){
    this.documents.forEach( (element) => {

        if(element.id == id) {
          if(element.fileName.toLowerCase().includes(".png") 
          || element.fileName.toLowerCase().includes(".jpg") 
          || element.fileName.toLowerCase().includes(".jpeg") 
          || element.fileName.toLowerCase().includes(".gif") 
          || element.fileName.toLowerCase().includes(".bmp") 
          || element.fileName.includes(".svg")){
            this.isImage = true;
            this.isDoc = false;
          }
          else{
            this.isDoc = true;
            this.isImage = false;
          }
          
          this.localhttp.get(`${this.apiUrl}docs/file?filename=` + element.fileName).subscribe(result => {
          this.currentDocument = `${this.apiUrl}StaticFiles/` + element.fileName ;
          this.showModal = !this.showModal;
          this.currentDocId = element.id;
        }, error => console.error(error));
        }
      
    });
    
  }
  clickDownloadDocument(){
    this.documents.forEach( (element) => {
      if(element.id == this.currentDocId){
        this.currentDocument = `${this.apiUrl}StaticFiles/` + element.fileName ;
         
      }
    });
  }
 currentDoc: any;
currentUserName: any;
currentComment: any;
userComment: any;
  clickComment(id: any){
    this.documents.forEach( (element) => {
      if(element.id == id){
        
        console.log("clicked comment"); 
        this.currentDoc = id.toString();
        this.currentUserName = this.userEmail;
      }
    });
    this.isShown = true;
  }
 
  submitComment() {
    console.log('ivideod is equltto');
     
    console.log('ivideod', this.userEmail);
       
    this.isShown = false;
    console.log('added email')

    let data = {
      
      "videoid": "",
      "documentid": this.currentDoc,
      "comment": this.userComment,
      "username":  this.authService.instance.getActiveAccount()?.username,
      "createdDate": new Date(),
      "approved": false
    };
    
    this.localhttp.post(`${this.apiUrl}comment`, data).subscribe(result => {
      console.log(result);
      
      
    }, error => {
          console.error(error);
           
        }
    );
  }
}

interface CloudDocument {
  id: string;
  fileName: string;
  fileData: string;
  fileType: any;
  createdDate: any;
  approved: any;
}