import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-card-page-visits",
  templateUrl: "./card-page-visits.component.html",
})
export class CardPageVisitsComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  public Operation: Operation[] = [];
  public Operations: Array<Operation>;
  
  constructor(http: HttpClient) {   
 
    http.get<Operation[]>(`${this.apiUrl}dashboard`).subscribe(result => {
      this.Operations = result;
      for(let i=0;i<this.Operations.length;i++){  
        console.log(this.Operations[i]);
        this.Operation.push(this.Operations[i]);
      }
      console.log(this.Operation);
    }, error => console.error(error));      

  }

  ngOnInit(): void {}
}
interface Operation {
  operation_Name: any;
  RequestsCount: any;
  AverageDuration: any;
  percentiles_50: any;
  percentiles_95: any;
  percentiles_99: any;
}