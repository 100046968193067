
import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {environment} from '../../../../environments/environment';
@Component({
  selector: "app-card-files",
  templateUrl: "./card-files.component.html",
})
export class CardFilesComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  public cloudFiles: CloudFiles[];
  currentVideoId: string = '10000';
  selectedFiles?: FileList;

  currentFile?: File;
  progress = 0;
  message = '';
  currentMsgToParent = '';
  fileInfos?: Observable<any>;
  localhttp: HttpClient;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";
  constructor(private uploadService: FileUploadService, private http: HttpClient,
    private ref: ChangeDetectorRef, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) { 
       
      http.get<User>(`${this.apiUrl}users/email?email=` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {       
     
      if(result.approved == true)
      {
        this.isAllowed = true;
        http.get<CloudFiles[]>(`${this.apiUrl}docs`).subscribe(result => {
        this.cloudFiles = result; //.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : 1).filter(x => x.approved == false);
        this.cloudfile = this.cloudFiles;
        this.cloudfile.forEach(element => {
          element.fileName = element.fileName.replace("https://nonprofitstorage.blob.core.windows.net/document/" , "")
        });
        }, error => console.error(error)); 
      } 
      else{
        this.isNotAllowed = true;
      }
        
      
       
      console.log(this.Users);
    }, error => console.error(error)); 
      
      
    
  }
  cloudfile: CloudFiles[];
  selectFile(event: any): void {
      this.selectedFiles = event.target.files;
    }
    request(): void {
      console.log(this.currentMsgToParent);
      console.log(this.currentUser);
      this.isAllowed = true;
    }
  upload(): void {
  this.progress = 0;
console.log(this.currentMsgToParent);
      if (this.selectedFiles) {
          const file: File | null = this.selectedFiles.item(0);

          if (file) {
              this.currentFile = file;

              this.uploadService.upload(this.currentFile, this.currentMsgToParent).subscribe({
                  next: (event: any) => {
                      if (event.type === HttpEventType.UploadProgress) {
                          this.progress = Math.round(100 * event.loaded / event.total);
                      } else if (event instanceof HttpResponse) {
                          this.message = event.body.message;
                          this.fileInfos = this.uploadService.getFiles();
                      }
                      const docupdate = {
                        "id": 0,
                        "fileName": file.name,
                        "fileData": this.currentMsgToParent,
                        "fileType": true,
                        "createdDate": "2023-10-09T14:19:26.156Z",
                        "approved": true
                      }

                      this.http.post(`${this.apiUrl}docs/update`, docupdate).subscribe(result => {
                        //this.documents = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true);
                        }, error => console.error(error)); 
                  },
                  error: (err: any) => {
                      console.log(err);
                      this.progress = 0;

                      if (err.error && err.error.message) {
                          this.message = err.error.message;
                      } else {
                          this.message = 'Could not upload the file!';
                      }

                      this.currentFile = undefined;
                  }
              });
          }

          this.selectedFiles = undefined;
      }
  }

  currentUser: string;
  public Users: Array<User>;
  activeAccount: any;
  isAllowed: boolean = false;
  isNotAllowed: boolean = false;
  ngOnInit(): void {

    this.fileInfos = this.uploadService.getFiles();
      console.log(this.fileInfos);

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.inProgress$
        .subscribe(() => {            
            this.checkAndSetActiveAccount();
        });
    }

    checkAndSetActiveAccount() {
      
      this.activeAccount = this.authService.instance.getActiveAccount();
      console.log(this.activeAccount.username);
      console.log(this.activeAccount.name);
      this.currentUser = this.activeAccount.username;
      if (!this.activeAccount && this.authService.instance.getAllAccounts().length > 0) {
          let accounts = this.authService.instance.getAllAccounts();         
      }
  }
}


interface CloudFiles {
  fileName: string;
  fileData: string;
  fileType: any;
  id: any;
  createdDate: any;
  approved: any; 
}; 
interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;
}

