import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const STORAGE_KEY = 'referrerSent';

@Injectable({
  providedIn: 'root'
})
export class ReferrerService {
  
  private apiUrl = environment.apiUrl; // Replace with your API endpoint
  constructor(private http: HttpClient) {}

  sendDataFromExternalReferrer(referrer: string) {
    if (referrer) { // || !referrer.includes(window.location.origin)
      //var urlReferrer = { referrer: referrer };
      const data = {
        "url": referrer,
        "createdDate": "2024-07-11T17:16:01.772Z"
      };
      // Send HTTP POST request to your API      
      this.http.post(`${this.apiUrl}referrer`, data).subscribe(result => {
        //this.documents = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == true);
        console.log('Data sent to API');
        }, error => console.error(error)); 

        this.http.post(`${this.apiUrl}referrer`, data).subscribe(result => {
          console.log(result);
          //Write thank you we will get back to very soon          
        }, error => {
              console.error(error);              
            }
        );
    }
  }
}