import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//Auth
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { MapsClientComponent } from "./views/current/maps/mapsclient.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";
import { ProgressDialog } from "./views/admin/loader/progress-dialog.component";
import { TableComponent } from "./views/auth/tables/table.component";
import { FileUploadComponent } from "./views/admin/file/files.component";
import { CommentComponent } from "./views/admin/comment/comment.component";
import { DonationComponent } from "./views/admin/donation/donation.component";
import { VideoUploadComponent } from "./views/admin/video/video.component";
import { RegistersComponent } from "./views/admin/register/register.component";
import { FriendsComponent } from "./views/admin/friends/friends.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";


// current views
import { VideoComponent } from "./views/current/video/video.component";
import { VideoPlayerComponent } from "./views/current/video/video.player.component";
import { VideosComponent } from "./views/current/videos/videos.component";
import { VideosPlayerComponent } from "./views/current/videos/videos.player.component";

//Doc component
import { DocumentComponent } from "./views/current/doc/doc.component";
import { TermsComponent } from "./views/current/terms/terms.component";
import { ContactComponent } from "./views/current/contact/contact.component";
import { AboutComponent } from "./views/current/about/about.component";
// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";


const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [
      MsalGuard
    ], 
    data:{ title: "Admin Page"},
    children: [
      { path: "dashboard", component: DashboardComponent , data:{ title: "Dashboard Page"}},
      { path: "settings", component: SettingsComponent, data:{ title: "Settings Page" }},
      { path: "files", component: FileUploadComponent, data:{ title: "Comment Page"} },
      { path: "comment", component: CommentComponent, data:{ title: "Files Page"} },
      { path: "donation", component: DonationComponent, data:{ title: "Donation Page" }},
      { path: "videos", component: VideoUploadComponent, data:{ title: "Videos Page" }}, 
      { path: "projects", component: TablesComponent, data:{ title: "Projects Page" }},
      { path: "maps", component: MapsComponent, data:{ title: "Maps Page" }},      
      { path: "friends", component: FriendsComponent, data:{ title: "Friends Page" }},      
      { path: "register", component: RegistersComponent , data:{ title: "Register Page"}},
      
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  // auth views
  {
    path: "auth", 
      component: AuthComponent,
      
      data:{ title : "Auth Page"},
    children: [
      { path: "login", component: LoginComponent, data:{ title: "Login Page"} },
      
      { path: "video", component: VideoComponent, data:{ title: "Videos Page" }},
      { path: "doc", component: DocumentComponent, data:{ title: "Document Page" }},
      { path: "donation", component: TableComponent, data:{ title: "Donation Page" }},
      { path: "youtubeplayer", component: VideoPlayerComponent, data:{ title: "Video Player Page" }},
      { path: "youtubeplayer", component: VideosPlayerComponent, data:{ title: "Videos Player Page" }},
      { path: "register", component: RegisterComponent , data:{ title: "Register Page"}},
      { path: "maps", component: MapsClientComponent, data:{ title: "Maps Client Page" }},
      { path: "", redirectTo: "login", pathMatch: "full", data:{ title: "Login Page" }},
      { path: "terms", component: TermsComponent, data:{ title: "Terms Page" }},
      { path: "contact", component: ContactComponent, data:{ title: "Contact Page" }},
      { path: "about", component: AboutComponent, data:{ title: "About-Us Page" }},
      
    ],
  },
    // auth views
    {
      path: "current", 
        component: AuthComponent,
       
        data:{ title : "Auth Page"},
      children: [
        
        { path: "videos", component: VideosComponent, data:{ title: "Video Page"} },
        { path: "doc", component: DocumentComponent, data:{ title: "Document Page" }},
        { path: "maps", component: MapsClientComponent, data:{ title: "Maps Client Page" }},
        { path: "terms", component: TermsComponent, data:{ title: "Terms Page" }},
        { path: "contact", component: ContactComponent, data:{ title: "Contact Page" }},
        { path: "about", component: AboutComponent, data:{ title: "About Page" }},
        { path: "donation", component: TableComponent, data:{ title: "Donation Page" }},
        { path: "youtubeplayer", component: VideoPlayerComponent, data:{ title: "Video Player Page" }},
        { path: "youtubeplayer", component: VideosPlayerComponent,  data:{ title: "Videos Player Page" }},
        { path: "", redirectTo: "video", pathMatch: "full", data:{ title: "Video Main Page"} },
      ],
    },
  // no layout views
  { path: "index", component: IndexComponent, data:{ title: "Index Page" }},
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  { path: "", component: LandingComponent , data:{ title: "SSC Aid Now"}},
  { path: "**", redirectTo: "", pathMatch: "full" , data:{ title: "SSC Aid Now"}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
