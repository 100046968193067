<div class="flex flex-wrap mt-4">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full  px-4">

<div *ngIf="!paidFor" class="row col-md-12 align-items-center justify-content-center">  
  
  <h1>Buy this item ${{product.price}} </h1>
  
</div> 
<div *ngIf="paidFor" class="row col-md-12 align-items-center justify-content-center">  
  
  <h1>Yay ${{product.price}} </h1>
  
</div> 
<div class="flex flex-wrap mt-4">
  <div class="w-auto mb-12 px-4">
    <app-card-donation></app-card-donation>
  </div>
   
</div>
<div class="flex w-full flex-wrap mt-4" #paypal></div>


</div>
</div>
</div>


	
