<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      (click)="toggleCollapseShow('bg-white m-2 py-3 px-6')"
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      [routerLink]="['/']"
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
    >
      
      <span class="hidden sm:block"> Home</span>
    </a>
    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <app-notification-dropdown class="block"></app-notification-dropdown>
      </li>
      <li class="inline-block relative">
        <app-user-dropdown class="block"></app-user-dropdown>
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
      [ngClass]="collapseShow"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a [routerLink]="['/']"
               class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">
               
              <img src="assets/img/sscnonprofit.jpeg" alt="Logo">
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              (click)="toggleCollapseShow('hidden')"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Admin Pages
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a [routerLink]="['/admin/dashboard']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminDashboard="routerLinkActive"
             [ngClass]="
              adminDashboard.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-tv mr-2 text-sm"
               [ngClass]="
                adminDashboard.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
            Dashboard
          </a>
        </li>
        <li class="items-center">
          <div class="flex items-center">
            <a class="text-xs uppercase py-3 font-bold block cursor-pointer"
            [routerLink]="['/admin/dashboard']"
            routerLinkActive
            #adminDonations="routerLinkActive"
            [ngClass]="
              adminDonations.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
            (click)="toggleModal()" >
            <i class="fas fa-table mr-2 text-sm"
               [ngClass]="
               adminDonations.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
              Donate
            </a>
          </div>
        </li>

        <li class="items-center">
          <a [routerLink]="['/admin/projects']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminTables="routerLinkActive"
             [ngClass]="
              adminTables.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-table mr-2 text-sm"
               [ngClass]="
                adminTables.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
            Projects
          </a>
        </li>
        <li class="items-center">
          <a [routerLink]="['/admin/files']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminFiles="routerLinkActive"
             [ngClass]="
              adminFiles.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-table mr-2 text-sm"
               [ngClass]="
                adminFiles.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
            Files
          </a>
        </li>
        <li class="items-center">
          <a [routerLink]="['/admin/videos']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminVideos="routerLinkActive"
             [ngClass]="
                adminVideos.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-table mr-2 text-sm"
               [ngClass]="
                 adminVideos.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
            Videos
          </a>
        </li>
        <li class="items-center">
          <a [routerLink]="['/admin/maps']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminMaps="routerLinkActive"
             [ngClass]="
              adminMaps.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-map-marked mr-2 text-sm"
               [ngClass]="
                adminMaps.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
            Maps
          </a>
        </li>        
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Profile
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">

        <li class="items-center">
          <a [routerLink]="['/admin/donation']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminDonation="routerLinkActive"
             [ngClass]="
             adminDonation.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-table mr-2 text-sm"
               [ngClass]="
               adminDonation.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
             Donations
          </a>
        </li>

        <li class="items-center">
          <a [routerLink]="['/admin/friends']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminFriends="routerLinkActive"
             [ngClass]="
             adminFriends.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-tools mr-2 text-sm"
               [ngClass]="
               adminFriends.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
              Friends
          </a>
        </li> 
        
        <li class="items-center">
          <a [routerLink]="['/admin/settings']"
          (click)="hideModal()" 
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminSettings="routerLinkActive"
             [ngClass]="
              adminSettings.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i class="fas fa-tools mr-2 text-sm"
               [ngClass]="
                adminSettings.isActive ? 'opacity-75' : 'text-blueGray-300'
              "></i>
              Profile
          </a>
        </li>
        <li class="items-center">
          
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      
      
      
         
     
    
    
    </div>
  </div>
</nav>
<div #draggable class="flex h-screen overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none justify-center items-center flex" style="background-image: url('assets/img/flag.png');">

  <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
    <div class="mt-3 text-center">
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
        <svg class="h-6 w-6 text-purple-600" fill="none" stroke="currentColor"
             viewBox="0 0 24 24"
             xmlnx="http://www.w.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7">
          </path>
        </svg>
      </div>
      
      <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
         
        <div class="p-5">
            <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Make a difference today</h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Support our cause and make a positive impact by clicking the donate button now!</p>
            <a href="#" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                 <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
            </a>
        </div>
    </div>
      <div class="mt-2 px-7 py-3">

        <div class="flex content-center items-center justify-center h-full" id="paypal-donate-button-container"></div>

      </div>
     
    </div>
  </div>
</div>
