

<div class="container mt-24 mx-auto px-4 py-40 h-full">

  <h2 class="text-2xl font-semibold mb-6 ">Contact Adminstration</h2>

  <div class="flex items-center mb-4">
    <input type="text" [(ngModel)]="searchQuery" class="rounded-l-lg px-4 py-2 border-t border-b border-l text-gray-800 border-gray-200 bg-white" placeholder="Search...">
    <button (click)="search()" class="rounded-r-lg px-4 py-2 border border-gray-300 bg-blue-500  hover:bg-blue-600">Search</button>
  </div>
  
  <div class="container mx-auto p-4">
   
   

    <div class="grid grid-cols-2 gap-4">
      <div *ngFor="let friend of filteredFriends" class="bg-white rounded-lg shadow-lg overflow-hidden flex">
        <img class="w-32 h-auto object-cover" src="https://via.placeholder.com/150" alt="Friend Image">
        <div class="p-6">
          <h2 class="text-xl font-semibold text-gray-800">{{ friend.name }}</h2>
          <p class="text-gray-600">Engineer</p>
          <p class="text-gray-600">Location: New York</p>
          <div class="mt-4">
            <div class="flex justify-around">
              <button (click)="startCall(friend)" class="bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 px-4 py-2 rounded-lg">Call</button>
              <button (click)="openModalWhatsApp(friend)" class="bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 px-4 py-2 rounded-lg">Text</button>
              <button (click)="openModalSms(friend)" class="bg-teal-500 text-white hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 px-4 py-2 rounded-lg">WhatsApp</button>              
            </div>
            <div *ngIf="isLoading" class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div *ngIf="isModalOpenSms" class="fixed inset-0 flex items-center justify-center z-10 overflow-auto bg-gray-900 bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-lg max-w-md w-full">
      <!-- Modal header -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold text-gray-800">Send Text</h2>
        <button class="text-gray-500 hover:text-gray-700" (click)="closeModalSms()">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <!-- Modal content: textarea and submit button -->
      <form #userSms = "ngForm" (ngSubmit)="submitFormSms()">
        <textarea class="w-full border rounded p-2 mb-4" rows="4" placeholder="Enter text..." 
        id="textareaSms" name="textareaSms" [(ngModel)]="textareaValueSms"></textarea>
        <button type="submit" class="bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded">
          Submit
        </button><div *ngIf="isLoading" class="spinner"></div>
      </form>
    </div>
  </div>
  <!-- Modal -->
  <div *ngIf="isModalOpenWhatsApp" class="fixed inset-0 flex items-center justify-center z-10 overflow-auto bg-gray-900 bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-lg max-w-md w-full">
      <!-- Modal header -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold text-gray-800">Send WhatsApp</h2>
        <button class="text-gray-500 hover:text-gray-700" (click)="closeModalWhatsApp()">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <!-- Modal content: textarea and submit button -->
      <form #userwApp= "ngForm" (ngSubmit)="submitFormWhatsApp()">
        <textarea class="w-full border rounded p-2 mb-4" rows="4" placeholder="Enter text..." [(ngModel)]="textareaValueWhatsApp"></textarea>
        <button type="submit" class="bg-blue-500 hover:bg-blue-600  font-bold py-2 px-4 rounded">
          Submit
        </button><div *ngIf="isLoading" class="spinner"></div>
      </form>
    </div>
  </div>
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full  px-4">

      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0">
      
        <h2 class="text-2xl font-semibold mb-6 ">Email Us</h2>
    
        <!-- Contact Form -->
        <form #userlogin = "ngForm" (ngSubmit) = "SendMessage()">
          <div class="mb-4">
            <label for="name" class="block text-sm font-medium ">Your Name</label>
            <input type="text" id="name" name="name" [(ngModel)]="userName" class="mt-1 p-2 w-full border rounded-md">
          </div>
    
          <div class="mb-4">
            <label for="email" class="block text-sm font-medium ">Email Address</label>
            <input type="email" id="email" name="email"  [(ngModel)]="userEmail" class="mt-1 p-2 w-full border rounded-md">
          </div>
    
          <div class="mb-4">
            <label for="message" class="block text-sm font-medium ">Your Message</label>
            <textarea id="message" name="message" rows="4"  [(ngModel)]="userMessage" class="mt-1 p-2 w-full border rounded-md"></textarea>
          </div>
          <div *ngIf="!showMessageSent" class="bg-blue-500 p-4 rounded-md shadow-md">
            <button  type="submit" style="max-width: 260px;" class="bg-blueGray-200 py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300">Submit</button>
            <div *ngIf="isLoading" class="spinner"></div>
          </div>
       
        </form>
        <!-- Alert div -->
        <div *ngIf="showMessageSent" class="bg-blue-500  p-4 rounded-md shadow-md">
            <p class="font-bold">Success!</p>
            <p>Thank you for contacting. If requested futher information, representative will reach you in the next 48 hours.</p>
        </div>
        <div *ngIf="showMessageSentFailed" class="bg-red-500  p-4 rounded-md shadow-md">
          <p class="font-bold">Failure!</p>
          <p>Provide email, name or message to use for the message</p>
      </div>
      </div>
    
  </div>
  </div>
 
</div>