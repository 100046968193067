<div class="flex flex-wrap">
  <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
    <app-card-line-chart></app-card-line-chart>
  </div>
  <div class="w-full xl:w-4/12 px-4">
    <app-card-bar-chart></app-card-bar-chart>
  </div>
</div>
<div class="flex flex-wrap mt-4">
  <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
    <app-card-page-visits></app-card-page-visits>
  </div>
  <div class="w-full xl:w-4/12 px-4">
    <app-card-social-traffic></app-card-social-traffic>
  </div>
</div>
