<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  [ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3
          class="font-semibold text-lg"
          [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
        >
          Open Projects
        </h3>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
            Project
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
            Budget
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
            Status
          </th>

          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
            Completion
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          ></th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <th
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
          >
            <img
              src="assets/img/sketch.png"
              class="h-12 w-12 bg-white rounded-full border"
              alt="..."
            />
            <span
              class="ml-3 font-bold"
              [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
            >
              Disabled
            </span>
          </th>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
           $50 USD (Monthly)
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <i class="fas fa-circle text-emerald-500 mr-2"></i>
            pending
          </td>

          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <div class="flex items-center">
              <span class="mr-2">100%</span>
              <div class="relative w-full">
                <div
                  class="overflow-hidden h-2 text-xs flex rounded bg-emerald-200"
                >
                  <div
                    style="width: 100%"
                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"
                  ></div>
                </div>
              </div>
            </div>
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
          >
          <button class="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal('disabled')">
            Details
           </button>
             <div *ngIf="showModalDisabled" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
               <div class="relative w-auto my-6 mx-auto max-w-6xl">
                 <!--content-->
                 <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                   <!--header-->
                   <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                     <h3 class="text-3xl font-semibold">
                       Disabled
                     </h3>
                     <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" (click)="toggleModal('disabled')">
                       <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                         ×
                       </span>
                     </button>
                   </div>
                   <!--body-->
                   <div class="relative p-6 flex-auto  text-left">                    
                    
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      SSC Nonprofit maximizes the impact of donation money by channeling it towards tailored <br/>
                      programs and resources that address the unique needs of the disabled community. <br/>
                      From adaptive technologies to specialized care services, every contribution<br/>
                      directly enhances the quality of life for individuals with disabilities, 
                      <br/>fostering a more inclusive and supportive environment
                    </p>
                    
                   </div>
                   <!--footer-->
                   <div class="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                     <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal('disabled')">
                       Close
                     </button>
  
                   </div>
                 </div>
               </div>
             </div>
             <div *ngIf="showModalDisabled" class="opacity-25 fixed inset-0 z-40 bg-white"></div>
          </td>
        </tr>
        <tr>
          <th
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
          >
            <img
              src="assets/img/angular.png"
              class="h-12 w-12 bg-white rounded-full border"
              alt="..."
            />
            <span
              class="ml-3 font-bold"
              [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
            >
              Orphanes
            </span>
          </th>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            $50 USD
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <i class="fas fa-circle text-red-500 mr-2"></i> delayed
          </td>

          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <div class="flex items-center">
              <span class="mr-2">73%</span>
              <div class="relative w-full">
                <div
                  class="overflow-hidden h-2 text-xs flex rounded bg-red-200"
                >
                  <div
                    style="width: 73%"
                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                  ></div>
                </div>
              </div>
            </div>
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
          >
          <button class="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal('orphans')">
            Details
           </button>
             <div *ngIf="showModalOrphanes" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
               <div class="relative w-auto my-6 mx-auto max-w-6xl">
                 <!--content-->
                 <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                   <!--header-->
                   <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                     <h3 class="text-3xl font-semibold">
                       Orphans
                     </h3>
                     <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" (click)="toggleModal('orphans')">
                       <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                         ×
                       </span>
                     </button>
                   </div>
                   <!--body-->
                   <div class="relative p-6 flex-auto  text-left">                    
                    
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      SSC Nonprofit allocates donation funds with utmost transparency and efficiency, 
                    </p>
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      directing resources to provide orphans with essential needs such as 
                    </p>
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      education, healthcare, and emotional support. 
                    </p>
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed ">
                      Every dollar donated is meticulously utilized to create a nurturing environment, 
                      <br>ensuring these children not only survive but thrive in a compassionate and secure community 
                    </p>
                   </div>
                   <!--footer-->
                   <div class="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                     <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal('orphans')">
                       Close
                     </button>
  
                   </div>
                 </div>
               </div>
             </div>
             <div *ngIf="showModalOrphanes" class="opacity-25 fixed inset-0 z-40 bg-white"></div>
          </td>
        </tr>
        <tr>
          <th
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
          >
            <img
              src="assets/img/react.jpg"
              class="h-12 w-12 bg-white rounded-full border"
              alt="..."
            />
            <span
              class="ml-3 font-bold"
              [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
            >
              Healthcare
            </span>
          </th>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            $4,400 USD
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <i class="fas fa-circle text-teal-500 mr-2"></i> on schedule
          </td>

          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <div class="flex items-center">
              <span class="mr-2">90%</span>
              <div class="relative w-full">
                <div
                  class="overflow-hidden h-2 text-xs flex rounded bg-teal-200"
                >
                  <div
                    style="width: 90%"
                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
                  ></div>
                </div>
              </div>
            </div>
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
          >
          <button class="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal('healthcare')">
            Details
           </button>
             <div *ngIf="showModalHealthcare" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
               <div class="relative w-auto my-6 mx-auto max-w-6xl">
                 <!--content-->
                 <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                   <!--header-->
                   <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                     <h3 class="text-3xl font-semibold">
                      Healthcare
                     </h3>
                     <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" (click)="toggleModal('healthcare')">
                       <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                         ×
                       </span>
                     </button>
                   </div>
                   <!--body-->
                   <div class="relative p-6 flex-auto  text-left">                    
                    
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      The SSC Nonprofit tirelessly works to improve healthcare accessibility, 
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      bridging gaps in underserved communities through innovative programs and partnerships.
                     
                    </p>
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed">
                      By advocating for equitable healthcare solutions and empowering individuals with knowledge, 
                    </p>
                    <p class="break-all my-4 text-blueGray-500 text-lg leading-relaxed ">
                      We strive to create a healthier and more inclusive society for all. 
                    </p>
                   </div>
                   <!--footer-->
                   <div class="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                     <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal('healthcare')">
                       Close
                     </button>
  
                   </div>
                 </div>
               </div>
             </div>
             <div *ngIf="showModalHealthcare" class="opacity-25 fixed inset-0 z-40 bg-white"></div>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</div>
