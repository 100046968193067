

<div class="container mx-auto px-4 h-full">
  <div *ngIf="isLoading" class="spinner"></div>
  <div *ngIf="isLoading != true" class="flex content-center items-center justify-center h-full">
    <div class="w-full  px-4">
      
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0">

        <!--video content-->
        <div #draggable class="flex overflow-x-hidden overflow-y-auto inset-0 outline-none focus:outline-none justify-center items-center flex">
          <div class="container mx-auto">
            <div class="flex content-center items-center justify-center ">

              <div class="border-0 rounded-lg shadow-lg relative flex l bg-white outline-none focus:outline-none">

                <corp-video-player [videoId]="currentVideoId"></corp-video-player>
              </div>
            </div>
          </div>
        </div>
       
        <div *ngIf="showDiv" class="flex content-center items-center justify-center ">

          <div class="px-6 pt-4 pb-2">
           <button class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" (click)="approveVideo(currentVideoId)">Approve</button>
           <button class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" (click)="clickComment()">Comment</button>
           <button class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" (click)="rejectVideo(currentVideoId)">Reject</button>
           
           <div *ngIf="isShown" class="row container-fluid text-blue-600"  style="color:blue" id="divshow" >
              <form class="w-full max-w-sm">
                <div class="flex items-center border-b border-teal-500 py-2">
                  <div class="flex p-2 space-x-4" style="min-width: 320px;">
                    <textarea [(ngModel)]="userComment" name="userComment" id="userComment" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write comments here..."></textarea>
                  </div>
                </div>
                <div class="flex p-2 space-x-4">
                  <a class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" (click)="commentVideo(currentVideoId)">Submit</a>
                </div> 
              </form>
              
              </div>
          </div>
      </div>
      <div class="relative bukas flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0">
        <!-- <ngx-glide #ngxGlide [autoplay]="true"-->
          
          <div *ngIf="isAllowed" class="max-w-md mx-auto p-8 "></div>
            <ng-container *ngIf="groupvideos && groupvideos.length > 0">
              <ngx-glide #ngxGlide > 
                <ng-container  *ngFor="let group of groupvideos ">
                  
                  <div class="grid grid-cols-3 gap-4 mt-6 rounded-md shadow-md" *ngFor="let video of group.slice(0, 1)">
                  
                    
                      <div *ngIf="group[0]" class="shadow-lg  flex content-center items-center justify-center">
                        <div class="max-w-sm rounded overflow-hidden shadow-lg">
                          <img class="w-full" [src]='group[0].thumbnail' alt="Sunset in the mountains" (click)="selectVideo(group[0].videoId)">
                          <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2"> {{group[0].title.substring(0, 10)}} ...</div>
                            <p class="text-gray-700 text-base"> 
                              {{group[0].title}}
                            </p>
                          </div>
                          
                        </div>
                      </div>

                      <div *ngIf="group[1]" class="shadow-lg flex content-center items-center justify-center">
                        <div class="max-w-sm rounded overflow-hidden shadow-lg">
                          <img class="w-full" [src]='group[1].thumbnail' alt="Sunset in the mountains" (click)="selectVideo(group[1].videoId)">
                          <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2"> {{group[1].title.substring(0, 10)}} ...</div>
                            <p class="text-gray-700 text-base">
                              {{group[1].title}}
                            </p>
                          </div>
                          
                        </div>
                      </div>
                      <div *ngIf="group[2]" class="shadow-lg flex content-center items-center justify-center">
                        <div class="max-w-sm rounded overflow-hidden shadow-lg">
                          <img class="w-full" [src]='group[2].thumbnail' alt="Sunset in the mountains" (click)="selectVideo(group[2].videoId)">
                          <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2"> {{group[2].title.substring(0, 10)}} ...</div>
                            <p class="text-gray-700 text-base">
                            {{group[2].title}}
                            </p>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  
                    
                </ng-container>
              </ngx-glide>
            </ng-container>
          </div>
          <div *ngIf="isNotAllowed" class="max-w-md mx-auto bg-white p-8 rounded-md shadow-md">
            <h2 class="text-2xl font-semibold mb-4">Request Access to Videos </h2>
          
            
            <div class="flex p-2 space-x-4">
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
              <textarea [(ngModel)]="currentMsgToParent" id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write reason to videos here..."></textarea>
            </div>
            <div class="flex p-2 space-x-4">
              <button class="px-4 py-2 text-white bg-red-500 rounded shadow-xl w-full"  (click)="request()">Submit</button>
            </div>
          
                <div class="flex items-center">
                    <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
                        Request Access
                    </button>
                </div>
          
          </div>
      </div>
    </div>
  </div>
  </div>


