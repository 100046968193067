import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit, ViewChild,  ElementRef, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
 
import { NgxGlideComponent } from 'ngx-glide';
import { environment } from "src/environments/environment";
@Component({
 
  styleUrls: [ './video.component.css' ],
  selector: "app-video",
  templateUrl: "./video.component.html",
  
})
export class VideoComponent implements OnInit {
  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent;

  names: string[];
  currentMsgToParent = '';
  public mobile: boolean = false;
  public videos: Video[] = [];
  public groupvideos: Video[][] = [];
  j = -1;
  @Input() href: string;
  localhttp: HttpClient;
  private apiUrl = environment.apiUrl;
  images: string[] = [];
  isLoading: boolean = false;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private el:ElementRef, 
    private ref: ChangeDetectorRef, private titleService:Title,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {   
      this.titleService.setTitle("Video Client Page");
     this.isLoading = true;
    this.localhttp = http;

    http.get<User>(`${this.apiUrl}users/email?email=` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {
       
         
          if(result.approved == true)
          {
            this.isAllowed = true;
            http.get<Video[]>(`${this.apiUrl}videos`).subscribe(result => {
              this.videos = result.sort((a, b) => (a.id > b.id) ? -1 : 1).filter(x => x.approved == false && x.title != "" && x.thumbnail != "");
              let userTestStatus: Video[] = [
                { "videoId": "iihJYAMIRP4", "thumbnail": "https://i.ytimg.com/vi/iihJYAMIRP4/hqdefault.jpg", "title":  "1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id:0 },
                { "videoId": "iDLWv3xM1s0", "thumbnail": "https://i.ytimg.com/vi/iDLWv3xM1s0/hqdefault.jpg", "title":  "2 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
                { "videoId": "bZQun8Y4L2A", "thumbnail": "https://i.ytimg.com/vi/6PRiAexITSs/hqdefault.jpg", "title":  "3 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
                { "videoId": "pJGDPEk45Jc", "thumbnail": "https://i.ytimg.com/vi/bZQun8Y4L2A/hqdefault.jpg", "title":  "4 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
                { "videoId": "hq6uEaMYf5U", "thumbnail": "https://i.ytimg.com/vi/hq6uEaMYf5U/hqdefault.jpg", "title":  "5 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
                { "videoId": "KMOV1Zy8YeM", "thumbnail": "https://i.ytimg.com/vi/KMOV1Zy8YeM/hqdefault.jpg", "title":  "6 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
                { "videoId": "sgIBC3yWa-M", "thumbnail": "https://i.ytimg.com/vi/sgIBC3yWa-M/hqdefault.jpg", "title":  "7 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
                { "videoId": "FyY0fEO5jVY", "thumbnail": "https://i.ytimg.com/vi/FyY0fEO5jVY/hqdefault.jpg", "title":  "8 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
                { "videoId": "8_0DJ9FOlOM", "thumbnail": "https://i.ytimg.com/vi/8_0DJ9FOlOM/hqdefault.jpg", "title":  "9 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 0 },
                { "videoId": "iihJYAMIRP4", "thumbnail": "https://i.ytimg.com/vi/iihJYAMIRP4/hqdefault.jpg", "title":  "10 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 },
                { "videoId": "6PRiAexITSs", "thumbnail": "https://i.ytimg.com/vi/6PRiAexITSs/hqdefault.jpg", "title":  "11 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.", approved: true, createdDate: "", id: 1 }
              ];
        
              //this.videos = userTestStatus;
        
              for (var i = 0; i < this.videos.length; i++) {
                if (i % 3 == 0) {
                    this.j++;
                    this.groupvideos[this.j] = [];
                    this.groupvideos[this.j].push(this.videos[i]);
                }
                else {
                    this.groupvideos[this.j].push(this.videos[i]);
                }
              }
              const data = JSON.parse(localStorage.getItem('myVideo'));
              if(data)
              {
               this.currentVideoId = data.videoid;
               localStorage.removeItem("myVideo");
              }
              else
              {
                this.currentVideoId = this.videos[0].videoId;
              }
              
              this.isLoading = false;
            }, error => console.error(error));
          } 
          else{
            this.isNotAllowed = true;
          }
        
      
       
      console.log(this.Users);
    }, error => console.error(error)); 


 
  }
  @ViewChild('draggable', { read: ElementRef, static:false }) draggable: ElementRef;
  currentUser: string;
  public Users: Array<User>;
  activeAccount: any;
  isAllowed: boolean = false;
  isNotAllowed: boolean = false;
  showDiv: boolean = false;
  request(): void {
    console.log(this.currentMsgToParent);
    console.log(this.currentUser);
    this.isAllowed = true;
  }
  ngOnInit(): void {

    if (window.screen.width === 360) { // 768px portrait
      this.mobile = true;
    }

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.inProgress$
        .subscribe(() => {            
            this.checkAndSetActiveAccount();
        });

        setTimeout(() => {
          this.showDiv = true;
        }, 20000); // Change delay time as needed (in milliseconds)
    }

    checkAndSetActiveAccount() {
      
      this.activeAccount = this.authService.instance.getActiveAccount();
      console.log(this.activeAccount.username);
      console.log(this.activeAccount.name);
      this.currentUser = this.activeAccount.username;
      if (!this.activeAccount && this.authService.instance.getAllAccounts().length > 0) {
          let accounts = this.authService.instance.getAllAccounts();         
      }
  }
  private apiLoaded2 = false
  ngAfterViewInit(): void {
      
    if (window.screen.width < 600) { // 768px portrait
      this.mobile = true;
    }
    this.showModal = true; 
    //invokecarousel();
  }

  showModal = true;
  currentVideoId: string;



  selectVideo(video: any) { 
    this.currentVideoId = video;
    this.draggable.nativeElement.classList.remove('hidden')
    console.log('removed hidden');
    let data = {
      'videoid': this.currentVideoId,
      'name': 'youtube'
    };
    localStorage.setItem('myVideo', JSON.stringify(data));
    //window.location.reload();
    this.ngOnInit();
  }
  approveVideo(video: any) {
    //Get the videoId and Set it is value to approved
    console.log('ivideod', video);
     
    let myvideo = this.videos.filter(x => x.videoId == video)[0];
    myvideo.approved = true;
    this.localhttp.post(`${this.apiUrl}videos`, myvideo).subscribe(result => {
     
      this.ref.detectChanges();
      window.location.reload();
      this.ngOnInit();
      
    }, error => console.error(error));    
    
    this.currentVideoId = video;
    
    console.log('removed hidden')
  }
  rejectVideo(video: any) {
    
    console.log('ivideod', video);
    let myvideo = this.videos.filter(x => x.videoId == video)[0];
    myvideo.approved = false;
    myvideo.thumbnail = "";
    myvideo.title = "";
    
    this.localhttp.post(`${this.apiUrl}videos/reject`, myvideo).subscribe(result => {
     
      this.ref.detectChanges();
      window.location.reload();
      this.ngOnInit();
      
    }, error => console.error(error));    
    
    this.currentVideoId = video;
    
    console.log('removed hidden')
  }

  commentVideo(videoid: any) {
    this.isShown = false;
    console.log('ivideod', videoid);
    let data = {
      "videoid": videoid,
      "documentid": "",
      "comment": this.userComment,
      "username":  this.authService.instance.getActiveAccount()?.username,
      "createdDate": new Date(),
      "approved": false
    };
     
    this.localhttp.post(`${this.apiUrl}comment`, data).subscribe(result => {
      console.log(result);
    }, error => {
          console.error(error);
    });
    console.log('removed hidden')
  }

  isShown: boolean = false 
  userComment: any;
  clickComment(){
     
    this.isShown = true;
  }
}

interface Video {
  videoId: string;
  title: string;
  thumbnail: string;
  id: number;
  createdDate: any;
  approved: any;
}
interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;
}
