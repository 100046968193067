import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit, ViewChild,  ElementRef, ChangeDetectorRef } from "@angular/core";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-card-settings",
  templateUrl: "./card-settings.component.html",
})
export class CardSettingsComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private el:ElementRef, 
    private ref: ChangeDetectorRef, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router, private titleService:Title) { 
      this.localhttp = http;
      http.get<User>(`${this.apiUrl}users/email?email=`+ this.authService.instance.getActiveAccount()?.username).subscribe(result => {
        this.Users = result;
        this.titleService.setTitle("Registration Page");
        console.log(this.Users);
        this.currentUser = this.Users.email;  
        this.currentName = this.Users.name;          
        console.log("redirecting");
        this.currentDescription = this.Users.description;
        this.currentPhone = this.Users.phone;
        this.currentLocation = this.Users.location;   
        this.emailOptIn = this.Users.emailOptIn;      
        this.smsOptIn = this.Users.smsOptIn;
        this.voiceCallOptIn = this.Users.voiceCallOptIn;
        this.currentWhatsAppNumber = this.Users.whatisAppNumber;
       
      console.log(this.Users);
    }, error => console.error(error));    
  }
  currentUser: string;
  currentPhone: string;
  currentLocation: string;
  currentDescription: string;
  currentName: string;
  currentWhatsAppNumber: string;
  localhttp: HttpClient;
  public Users: User;
  activeAccount: any;
  voiceCallOptIn: boolean = false;
  smsOptIn: boolean = false;
  emailOptIn: boolean = false;

  InsertUpdateUser() {
    console.log('user', this.currentUser);
    console.log('user', this.currentDescription);
    console.log('user', this.currentLocation);
    console.log('email', this.emailOptIn);
   
    //user.Approved = false;
    let data = {
      "id": 0,
      "name": this.currentName,
      "email": this.currentUser,
      "phone": this.currentPhone,
      "description": this.currentDescription,
      "location": this.currentLocation,
      "createdDate": new Date(),
      "approved": false,
      "emailOptIn": this.emailOptIn,
      "smsOptIn": this.smsOptIn,
      "voiceCallOptIn": this.voiceCallOptIn,
      "WhatisAppNumber": this.currentWhatsAppNumber
    };
    this.localhttp.post(`${this.apiUrl}users` , data).subscribe(result => {
     
    this.router.navigate(['/admin/settings']);
      
    }, error => console.error(error));

  }

  registerPhone(): void { 
    this.router.navigate(['/admin/register']);
  }

  ngOnInit(): void {}

}

interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  location: string;
  imageUrl: string;
  createdDate: any;
  approved: any;
 emailOptIn: boolean;
  smsOptIn: boolean;
  voiceCallOptIn: boolean;
  whatisAppNumber: string;
}