<div class="lg:flex items-center justify-center w-full " >
  <div *ngIf="isLoading" class="spinner"></div>
    <div *ngIf="showModal"  class="opacity-25 fixed inset-0 z-40 bg-black container w-full h-full overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center items-center flex min-w-full">
      <div class="relative p-6 w-auto m-6 mx-auto w-10/12 h-full" >
        <!--content-->
        <div class="w-3/4 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
           
            <button style="margin-top: 45px;" class="text-red-500 background-transparent font-bold uppercase my-8  px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
                X
              </button>
             
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto" style="margin-top: -45px;" >
           
                <div *ngIf="isDoc">
                    <p class="-my-8 text-blueGray-500 text-lg leading-relaxed min-w-fit md:min-w-480 lg:min-w-680  min-w-[90%]">
                    <ngx-doc-viewer url="{{ currentDocument }}" viewer="google" style="width:100%;height:93vh;"></ngx-doc-viewer>
                </p>
                </div>
              
                <div *ngIf="isImage">
                    <p class="-my-8 text-blueGray-500 text-lg leading-relaxed min-w-fit md:min-w-480 lg:min-w-680  min-w-[90%]">
                  <img src="{{ currentDocument }}">
                  </p>
                </div>
                
         
          </div>
          <!--footer-->
          
        </div>
      </div>
    </div>
</div>

<div *ngIf="showModal2" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
<div *ngIf="!showModal" class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-10/12 ">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0"
        >
       
<div class="container mx-auto px-4 h-full py-10 ">
<div  aria-label="group of cards" tabindex="0" class="focus:outline-none py-16 w-full ">
 
    <div *ngFor="let doc of documents" class="flex flex-col gap-4">
        <div tabindex="0" aria-label="card 1" class="focus:outline-none lg:w-full lg:mr-7 lg:mb-0 mb-7 bg-white dark:bg-gray-800  p-6 shadow rounded">
            <div class="flex items-center border-b border-gray-200 dark:border-gray-700  pb-6">
                <img src="https://cdn.tuk.dev/assets/components/misc/doge-coin.png" alt="coin avatar" class="w-12 h-12 rounded-full" />
                <div class="flex items-start justify-between w-full">
                    <div class="pl-3 w-full">
                        <p tabindex="0" class="focus:outline-none text-xl font-medium leading-5 text-gray-800 dark:text-white ">{{ doc.fileName }}</p>
                        <p tabindex="0" class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 dark:text-gray-200 ">{{ doc.createdDate }}</p>
                    </div>
                    <div role="img" aria-label="bookmark">
                       <svg  class="focus:outline-none dark:text-white text-gray-800" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5001 4.66667H17.5001C18.1189 4.66667 18.7124 4.9125 19.15 5.35009C19.5876 5.78767 19.8334 6.38117 19.8334 7V23.3333L14.0001 19.8333L8.16675 23.3333V7C8.16675 6.38117 8.41258 5.78767 8.85017 5.35009C9.28775 4.9125 9.88124 4.66667 10.5001 4.66667Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                    </div>
                    
                </div>
            </div>
            <div class="px-2">
                <p tabindex="0" class="focus:outline-none text-sm leading-5 py-4 text-gray-600 dark:text-gray-200 ">
                  {{ doc.fileData }}
                    </p>
                    
                <div tabindex="0" class="focus:outline-none flex">
                    <div class="py-2 px-4 text-xs leading-3 text-indigo-700 rounded-full bg-indigo-100 ccursor-pointer"><a  (click)="clickShow(doc.id)"> Open </a></div>
                    <div class="py-2 px-4 ml-3 text-xs leading-3 text-indigo-700 rounded-full bg-indigo-100 cursor-pointer"><a href="{{this.currentDocument}}"> Download </a></div>
                    <div class="py-2 px-4 ml-3 text-xs leading-3 text-indigo-700 rounded-full bg-indigo-100 cursor-pointer"><a  (click)="clickComment(doc.id)"> Comment </a></div>
                    
                </div>
                <div *ngIf="isShown" class="row container-fluid text-blue-600"  style="color:blue" id="divshow" >
                  <form class="w-full max-w-sm">
                    <div class="flex items-center border-b border-teal-500 py-2">
                      <div class="flex p-2 space-x-4">
                        <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                        <textarea [(ngModel)]="userComment" name="userComment" id="userComment" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write comments here..."></textarea>
                      </div>
 
                      <a  class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" (click)="submitComment()">Submit</a>
                       
                    </div>
                  </form>
                  
                  </div>
            </div>
        </div>
    </div>
    
</div>




</div>
</div>
</div>
</div>
</div>
