import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'corp-videos-player',
  template: `
    <youtube-player [videoId]="videoId" [playerVars]="playerConfig" (ready)="onReady($event)"></youtube-player>
   
  `,
  styles: [
  ]
})
export class VideosPlayerComponent implements OnInit {
  private apiLoaded = false
  playerConfig = {
    controls: 0,
    mute: 0,
    autoplay: 1
  };

  @Input() videoId: string;

  constructor() { }

  ngOnInit(): void {     
    if(!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }    
  }

  onReady(e): void {
    console.log(e, 'its ready')
  }

}
