<a
  class="text-white lg:hover:text-blueGray-200 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
  href="#pablo"
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
<i class="text-white text-blueGray-400 fa fa-lock text-lg leading-lg mr-2"></i>
  Admin
</a>
<div
  class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48" 
  [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  #popoverDropdownRef
>
 
  <a
    [routerLink]="['/admin/dashboard']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Dashboard
  </a>
 
 
  <a
  [routerLink]="['/admin/videos']"
  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
>
  Videos
</a>
  <a
    [routerLink]="['/admin/settings']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
  Profile
  </a>
  <a
    [routerLink]="['/admin/donation']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
  Projects
  </a>
  <a
  [routerLink]="['/admin/files']"
  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
>
  Files
</a>
  <a
    [routerLink]="['/admin/maps']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Maps
  </a>
  <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100"></div>
  <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100"></div>
   <a
    [routerLink]="['/admin/friends']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Friends
  </a>
  
  <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100"></div>
   <a
    [routerLink]="['/admin/settings']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Profile
  </a>
</div>
