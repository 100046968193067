<footer class="relative bg-blueGray-200 pt-8 pb-6">
  <div
    class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
    style="transform: translateZ(0)"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-200 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap text-center lg:text-left">
      <div class="w-full lg:w-6/12 px-4">
        <h4 class="text-3xl font-semibold"> {{ 'landing.cat14Title' | translate }}</h4>
        <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
          {{ 'landing.cat14SubTitle' | translate }} 
        </h5>

       
    
        <div class="mt-6 lg:mb-0 mb-6">
          <button
            class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
            type="button"
          >
          <a
            class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://twitter.com/sscaidnow"
            target="_blank"
          >
            <i class="fab fa-twitter"></i>
            </a>
          </button>
          <button
            class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
            type="button"
          >
            <i class="fab fa-facebook-square"></i>
          </button>
          <button
            class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
            type="button"
          >
            <i class="fab fa-dribbble"></i>
          </button>
          <button
            class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
            type="button"
          >
            <i class="fab fa-github"></i>
          </button>
         
        </div>
      </div>
      
      
      <div class="w-full max-w-lg mx-auto rounded-lg shadow-lg p-4 flex flex-col space-y-4" >
        <!-- Header with Avatar -->
        <div class="flex items-center space-x-4">
            <img src="https://placehold.co/60x60/lightblue/white?text=SSC\nAid+Now" alt="Avatar" class="w-12 h-12 rounded-full">
            <h2 class="text-xl font-semibold">Chat</h2>
        </div>
    
        <!-- Messages Display -->
        <div class="flex-1 bg-cool-blue-500 p-4 rounded-lg overflow-y-auto" style="max-height: 300px;">
            <div class="space-y-2" #messagesDisplay>
                <div *ngFor="let message of messages" class="flex items-start space-x-2">
                    <img src="https://placehold.co/40x40/lightblue/white?text=SSC\nAid+Now" alt="Avatar" class="w-10 h-10 rounded-full">
                    <div class="bg-blue-100 p-2 rounded-lg">
                        <p class="text-sm">{{ message }}</p>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Input Area -->
        <div class="flex space-x-2 items-center">
            <input [(ngModel)]="messageInput" type="text" placeholder="Type your message" class="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400">
            <button (click)="sendMessage()" class="bg-blue-500 text-blueGray-800 p-2 rounded-lg hover:bg-blue-600">Send</button>
            <div *ngIf="isLoading" class="spinner"></div>
        </div>
    </div>
  
    </div>
    <hr class="my-6 border-blueGray-300" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        
        <div class="text-sm text-blueGray-500 font-semibold py-1">
          Copyright © {{ date }} 
          <a
            href="/#/current/terms"
            class="text-blueGray-500 hover:text-blueGray-800"
          >
          {{ 'landing.cat14Body' | translate }}
          </a>
          .
          <a
            href="/#/current/contact"
            class="text-blueGray-500 hover:text-blueGray-800"
          >
          Contact Us
          </a>
          .
        </div>
      </div>
      
    </div>
  </div>
</footer>
