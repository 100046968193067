import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, ChangeDetectorRef, Inject } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-card-file",
  templateUrl: "./card-file.component.html",
})
export class CardFileComponent implements OnInit {
  private apiUrl = environment.apiUrl;
  comments: any;
  originalcomments:any;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(private http: HttpClient,
    private ref: ChangeDetectorRef, 
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) { 
       
      http.get<Comment>(`${this.apiUrl}comment/` + this.authService.instance.getActiveAccount()?.username).subscribe(result => {       
        this.originalcomments = result;
      for (let i=0; i < this.originalcomments.length; i++){
        this.originalcomments[i].username = "https://i.ytimg.com/vi/" + this.originalcomments[i].videoId + "/hqdefault.jpg";
        this.originalcomments[i].videoId = `${this.apiUrl}current/videos?Id=` + this.originalcomments[i].videoId;
      }
       //.sort((a, b) => (a.createdDate > b.createdDate) ? -1 : 1).filter(x => x.approved == false);
       this.comments =  this.originalcomments;
       
    }, error => console.error(error)); 
      
      
    
  }

  ngOnInit(): void {}

  showModal = false;
  toggleModal(){
    this.showModal = !this.showModal;
  }
  
}

interface Comment
{
  videoid: string,
  documentid: string,
  comment: string,
  username: string,
  createdDate: any,
  approved: boolean
}
