<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  [ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3
          class="font-semibold text-lg"
          [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
        >
          Comments and Likes
        </h3>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- comment table -->
    <div *ngFor="let comment of comments" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <!-- Item 2 -->
      <div class="bg-white rounded-lg shadow-md p-4">
        <div class="flex">
          <!-- Image Column -->
          <div class="flex-none">
            <a [href]="comment.videoId" class="block w-full">
              <img [src]="comment.username" alt="Image 1" class="w-16 h-16 object-cover rounded-full">
            </a>
          </div>
          <!-- Description Column -->
          <div class="ml-4">
            <h3 class="text-lg font-semibold">{{comment.comment}}</h3>
            <p class="text-gray-600">Description of item 2 goes here.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


